import React from "react"
import { Button, Card, CardActions, CardContent, CardHeader, Grid, Stack } from '@mui/material'
import { Traduccion } from "../../comp/Traduccion"
import { DataTable } from '../../comp/DataTableSimple'
import { esCantidad, esImporte } from "../../utils/Formatter"
import { Normal } from '../../comp/Texto'
import { GraficoBarras, GraficoTorta } from "../../comp/Graficos"
import { relativeDate } from "../../utils/DateUtil"
import { reportePoductosValorYStockPorCategoria, reportePreciosObsoletos } from "../../services/servProductos"
import { Tipos } from "../../utils/Tipos"
import { ModalDetail } from '../../comp/ModalDetail'
import { Acciones } from "../../utils/Acciones"
import { LoadingRect } from "../../comp/LoadingComp"

export const ReportesProductos = () => {

    const [resServProductos, setResServProductos] = React.useState([])
    const [isServProductosLoading, setIsServProductosLoading] = React.useState(false)

    const [servPreciosObsoletos, setServPreciosObsoletos] = React.useState([])
    const [isServPreciosObsoletosLoading, setIsServPreciosObsoletosLoading] = React.useState(false)

    const [isOpenModalPreciosObsoletos, setIsOpenModalPreciosObsoletos] = React.useState(false)

    const variedadesPorCategoria = () => {
        let aux = []
        aux[0] = resServProductos.map(e => ({categoriaId: e.categoria.id, categoriaNombre: e.categoria.nombre, variedades: e.variedades}))
        return aux
    }

    const formatLabelCategorias = (labelValue) => resServProductos.length
        ? (resServProductos
            .find(f => f.categoria.id === labelValue).categoria.nombre
        ).split(' ').join('\n')
        : ''
    
    const formatDataVariedades = (dataValue) => dataValue > 1
        ? `${dataValue} ${Traduccion('TXT_PRODUCTOS')}`
        : `${dataValue} ${Traduccion('TXT_PRODUCTO')}`

    const dataKeysAndLabelsVariedades = {
        0: 'variedades'
    }

    /* para stock por categoría */

    const stockPorCategoria = () => resServProductos.map(e => ({
        categoriaId: e.categoria.id,
        categoriaNombre: e.categoria.nombre,
        stock: e.stock
    }))
    
    const formatDataStock = (dataValue) =>
        `${esCantidad(dataValue)} ${Traduccion(dataValue === 1 ? 'TXT_UNIDAD' : 'TXT_UNIDADES')}`

    const dataKeysAndLabelsStock = {
        stock: Traduccion('TXT_CANTIDAD')
    }

    React.useEffect(() => {
        setIsServProductosLoading(true)
        reportePoductosValorYStockPorCategoria({}).then(res => {
            setResServProductos(res.data)
            setIsServProductosLoading(false)
        })
        setIsServPreciosObsoletosLoading(true)
        reportePreciosObsoletos().then(res => {
            let aux = res.data
            aux.forEach(e => {
                e.actualizado = relativeDate(new Date(e.ultimaModificacion))
                    .map(e2 => typeof e2 === 'string' ? Traduccion(e2) : e2).join(' ')
                e.nombreEllipsis = e.nombre.length < 30
                    ? e.nombre
                    : e.nombre.substring(0, 27).concat('...')
            })
            setServPreciosObsoletos(aux)
            setIsServPreciosObsoletosLoading(false)
        })
    }, [])

    return <>
    <Normal>{Traduccion('TXT_ESTADO_ACTUAL')}</Normal>
    <br/>
    <Grid container spacing={2}>
        <Grid item xs={12} sm='auto'>
            <Card>
                <CardHeader title={Traduccion('TXT_VARIEDADES_POR_CATEGORIA')} />
                <CardContent>
                    <GraficoTorta
                        dataset={variedadesPorCategoria()}
                        isLoading={isServProductosLoading}
                        labelKey='categoriaId'
                        labelFormatter={formatLabelCategorias}
                        dataKeysAndLabels={dataKeysAndLabelsVariedades}
                        dataFormatter={formatDataVariedades}
                        />
                </CardContent>
            </Card>
        </Grid>
        <Grid item xs={12} sm={'auto'}>
            <Card>
                <CardHeader title={Traduccion('TXT_STOCK_POR_CATEGORIA')} />
                <CardContent>
                    <GraficoBarras dataset={stockPorCategoria()}
                        isLoading={isServProductosLoading}
                        labelKey='categoriaId'
                        labelFormatter={formatLabelCategorias}
                        dataAxisDesc={Traduccion('TXT_CANTIDAD_UN')}
                        dataKeysAndLabels={dataKeysAndLabelsStock}
                        dataFormatter={formatDataStock}
                        horizontal
                        />
                </CardContent>
            </Card>
        </Grid>
        <Grid item xs={12} sm={'auto'}>
            <Stack spacing={{xs:2}}>
                <Card>
                    <CardHeader title={Traduccion('TXT_VALOR_STOCK_COMPRA')} />
                    <CardContent sx={{ textAlign: 'right' }}>
                        { isServProductosLoading
                            ? <LoadingRect rows={1}/>
                            : <Normal sx={{fontWeight: 'bold'}}>
                                {esImporte(resServProductos.map(e => e.valorCompra).reduce((a,b) => a+b, 0))}
                            </Normal>
                        }
                    </CardContent>
                </Card>
                <Card>
                    <CardHeader title={Traduccion('TXT_VALOR_STOCK_VENTA')} />
                    <CardContent sx={{ textAlign: 'right' }}>
                    { isServProductosLoading
                        ? <LoadingRect rows={1}/>
                        : <Normal sx={{fontWeight: 'bold'}}>
                            {esImporte(resServProductos.map(e => e.valorVenta).reduce((a,b) => a+b, 0))}
                        </Normal>
                    }
                    </CardContent>
                </Card>
            </Stack>
        </Grid>
        <Grid item xs={12} md='auto'>
            <Card>
                <CardHeader title={Traduccion('TXT_PRECIOS_OBSOLETOS')} />
                <CardContent>
                    <DataTable headers={['TXT_CODIGO', 'TXT_NOMBRE', 'TXT_ACTUALIZADO']}
                        data={servPreciosObsoletos.slice(0,5)}
                        isLoading={isServPreciosObsoletosLoading}
                        types={[Tipos().COD, Tipos().DESC, Tipos().DESC]}
                        access={['codigo', 'nombreEllipsis', 'actualizado']}
                        />
                </CardContent>
                <CardActions disableSpacing>
                    <Button onClick={() => setIsOpenModalPreciosObsoletos(true)}
                        color="primary"
                        size="small"
                        style={{marginLeft: 'auto'}}
                        disabled={isServPreciosObsoletosLoading}>
                        {Traduccion('TXT_VER_TODOS')} ({servPreciosObsoletos.length})
                    </Button>
                </CardActions>
            </Card>
            <ModalDetail accion={Acciones().SEE}
                isOpen={isOpenModalPreciosObsoletos}
                onSubmit={() => setIsOpenModalPreciosObsoletos(false)}
                customTitle={'TXT_PRECIOS_OBSOLETOS'}
                maxWidth='lg'>
                <DataTable headers={['TXT_CODIGO', 'TXT_NOMBRE', 'TXT_COSTO_MIN', 'TXT_VENTA_MIN', 'TXT_ACTUALIZADO']}
                        data={servPreciosObsoletos}
                        isLoading={isServPreciosObsoletosLoading}
                        types={[Tipos().COD, Tipos().DESC, Tipos().IMP, Tipos().IMP, Tipos().DESC]}
                        access={['codigo', 'nombre', 'precioCosto', 'precioVenta', 'actualizado']}
                        />
            </ModalDetail>
        </Grid>
    </Grid>
    </>
}