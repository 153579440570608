import { getToken } from '../config/Sesion'

export const Acceso = (vista, nivel) => {
    var tieneElPermiso = getToken().lda.filter(e => e.split('-')[1] === vista);
    if (tieneElPermiso.length > 0) {
        return tieneElPermiso[0].split('-')[0] >= nivel;
    }
    return false;
};

export const NivelAcceso = () => {
    return {
        VER: 1,
        AGREGAR: 2,
        EDITAR: 3,
        BORRAR_REINSERTAR: 4,
        CONTROLAR_TODO: 5
    };
};