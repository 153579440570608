import React from 'react'
import { useNavigate } from 'react-router-dom'

import { DataTable } from '../../comp/DataTableV2'
import { Traduccion } from '../../comp/Traduccion'

import { setCajaOperada } from '../../config/Sesion'

import { Acceso, NivelAcceso } from '../../utils/Seguridad'
import { Acciones } from '../../utils/Acciones'
import { Tipos } from '../../utils/Tipos'

import { listar, abrir } from '../../services/servCajas1'
import { Detalle, Filtros } from './contenido'

const vista = 'ViewCajas';
const txtAbierta = Traduccion('TXT_ABIERTA');
const txtCerrada = Traduccion('TXT_CERRADA');

export const Cajas = () => {

    const [tData, setTData] = React.useState([])
    const [filters, setFilters] = React.useState({ user: '', desde: null, hasta: null })
    const [isSearchOpened, setIsSearchOpened] = React.useState(false)
    const [isDetailOpened, setIsDetailOpened] = React.useState(false)
    const [isTableLoading, setIsTableLoading] = React.useState(false)
    const [modalAction, setModalAction] = React.useState(Acciones().SEE)
    const [isLoading, setIsLoading] = React.useState(false)
    const [seleccion, setSeleccion] = React.useState([])

    const navigate = useNavigate()

    const _handleOpenDetail = (action, selection) => {
        var seleccion = selection ? tData.filter(e => e.id === selection) : {};
        if (action === Acciones().SEE) {
            setCajaOperada(seleccion.length > 0 ? seleccion[0] : {});
            navigate('/cajas/movimientos/');
        } else {
            setIsDetailOpened(true)
            setModalAction(action)
            setSeleccion(seleccion)
        }
    }

    const _handleSearchSubmit = (submit, filtros) => {
        setIsSearchOpened(false)
        if (submit) {
            setFilters(filtros)
        }
    }

    const _handleDetailSubmit = (submit, obj) => {
        if (!submit) {
            setIsDetailOpened(false)
            return
        }
        setIsLoading(true)
        switch (modalAction) {
            case Acciones().ADD:
                abrir(obj).then(respOpen => {
                    if (respOpen.length >= 0) {
                        alert(Traduccion('MSJ_OPERACION_FALLIDA') + ':\r* ' + respOpen.map(d => Traduccion(d.codigo)).join('\r* '))
                        setIsLoading(false)
                        return
                    }
                    setIsDetailOpened(false)
                    listar({id: respOpen.datos, user: ''}).then(respDatosCaja => {
                        setCajaOperada(respDatosCaja.datos[0]);
                        navigate('/cajas/movimientos/nuevo');
                    })
                })
                break
            default: alert("Operacion no admitida"); break;
        }
    }

    const _search = (filtros) => {
        setIsTableLoading(true)
        listar(filtros).then(resp => {
            resp.datos.forEach(e => {
                e.estado = e.activo ? txtAbierta : txtCerrada
            })
            setTData(resp.datos)
            setIsTableLoading(false)
        })
    }

    React.useEffect(()=>{
        _search(filters)
    },[filters])

    return <div>
        <DataTable title={vista}
                   headers={['TXT_CAJERO', 'TXT_PUNTO_DE_VENTA', 'TXT_FECHA_APERTURA', 'TXT_ESTADO']}
                   types={[Tipos().DESC, Tipos().NUM, Tipos().FECHA, Tipos().DESC]}
                   access={['usuarioOperacion.user', 'puntoDeVenta.numero', 'fechaApertura', 'estado']}
                   data={tData}
                   rowId="id"
                   openSearch={() => setIsSearchOpened(true)}
                   openDetail={_handleOpenDetail}
                   hasAdd={Acceso(vista, NivelAcceso().AGREGAR)}
                   isLoading={isTableLoading}
                   showStatusTootip={false}/>
        <Filtros values={filters}
                 isOpen={isSearchOpened}
                 onSubmit={_handleSearchSubmit}/>
        <Detalle isOpen={isDetailOpened}
                 onSubmit={_handleDetailSubmit}
                 seleccion={seleccion[0]}
                 isSubmiting={isLoading}/>
    </div>

}