import jwt from 'jsonwebtoken'
import { getTenantInfo, setLastTenantUser, updateTenantInfo } from './Storage'

export const inicializarSesion = (user, tenantId, token) => {
    setToken(token);
    setLastTenantUser(user, tenantId);
    updateTenantInfo(user.tenants.find(e => e.id === tenantId));
};

const setToken = (token) => {
    sessionStorage.setItem('token', token);
};

export const getToken = () => {
    return getEncodedToken() ? jwt.decode(getEncodedToken()) : null;
};

export const getEncodedToken = () => {
    return sessionStorage.getItem('token');
};

export const Tenant = () => {
    var tenantID = getToken().jti.split('-')[0] * 1;
    return getTenantInfo(tenantID);
};

export const User = () => {
    let token = getToken();
    if (token) {
        return {
            id: token.jti.split('-')[1],
            user: token.sub,
            nombre: token.name
        };
    }
    return {
        id: null,
        user: null,
        nombre:null
    };
};

export const SesionActiva = () => {
    return getEncodedToken() ? true : false;
};

export const Config = () => {
    return {
        decimalesImporte: 2,
        decimalesCantidad: 2,
        separadorDecimal: ',',
        separadorDeMiles: '.',
        simboloMonetario: '$'
    };
};

export const setCajaOperada = (cajaOperada) => {
    sessionStorage.setItem('cajaOperada', JSON.stringify(cajaOperada));
};

export const getCajaOperada = () => {
    let aux = sessionStorage.getItem('cajaOperada');
    return aux ? JSON.parse(aux) : {};
};