import { UrlCor } from  '../config/Services'
import { GetCall as Get,
         GetCallPdfResp as GetPdf,
         PostCall as Post,
         PutCall as Put } from './_serviceHelper'

const urlBase = UrlCor();

export const GetCall = (url, params, setStatus) => Get(urlBase, url, params, setStatus);
export const GetCallPdfResp = (url, params, setStatus) => GetPdf(urlBase, url, params, setStatus);
export const PostCall = (url, params, setStatus) => Post(urlBase, url, params, setStatus);
export const PutCall = (url, params, setStatus) => Put(urlBase, url, params, setStatus);