import React from 'react';
import { DataTable } from '../../comp/DataTableSimple'
import { Traduccion } from '../../comp/Traduccion'

import { resumenMovimientos } from '../../services/servCajasMovimientos'

import { Tipos } from '../../utils/Tipos'

export const ResumenCierre = ({caja, reload}) => {

    const [isLoading, setIsLoading] = React.useState(false);
    const [datos, setDatos] = React.useState([]);

    React.useEffect(() => {
        if (reload) {
            setIsLoading(true);
            resumenMovimientos(caja).then(data => {
                //data.find(e => e.tipoCobro.descripcion === 'TXT_SALDO_APERTURA').tipoCobro.descripcion = Traduccion('TXT_SALDO_APERTURA');
                let datos = [];
                data.forEach(r => {
                    r.tipoCobro.descripcion = r.tipoCobro.id < 1
                        ? Traduccion(r.tipoCobro.descripcion)
                        : r.tipoCobro.descripcion
                    let resumen = datos.find(e => e.tipoCobro.id === r.tipoCobro.id);
                    if (resumen) {
                        resumen.totalCobrado += r.totalCobrado;
                    } else {
                        datos.push(r);
                    }
                });
                setDatos(datos);
                setIsLoading(false);
            });
        }
    }, [caja, reload]);

    return <DataTable headers={['TXT_DESCRIPCION', 'TXT_IMPORTE']}
               types={[Tipos().DESC, Tipos().IMP, Tipos().IMP, Tipos().IMP]}
               access={['tipoCobro.descripcion', 'totalCobrado']}
               data={datos}
               isLoading={isLoading}
               totalizar={-1}/>;
};