import 'date-fns'
import React from 'react'
import { MobileDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { esES } from '@mui/x-date-pickers/locales';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { es } from "date-fns/locale"

import { PropTypes } from 'prop-types'

export function InputFecha({ label, value = null, onChange }) {

    return <>
        <LocalizationProvider dateAdapter={AdapterDateFns}
            adapterLocale={es}
            localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
            <MobileDatePicker
                format="dd/MM/yyyy"
                margin="dense"
                inputVariant="outlined"
                fullWidth={true}
                label={label}
                value={value}
                onChange={fecha => onChange(fecha === '' ? null : fecha)}
                slotProps={{
                    textField: { fullWidth: true },
                    actionBar: { actions: ['clear', 'cancel', 'accept'] }
                }} />
        </LocalizationProvider>
    </>
}

InputFecha.propTypes = {
    label: PropTypes.string.isRequired,
    //value: PropTypes.instanceOf(Date),
    onChange: PropTypes.func.isRequired
}

export function InputPeriodo({ label, value = null, onChange }) {

    return <>
        <LocalizationProvider dateAdapter={AdapterDateFns}
            adapterLocale={es}
            localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
            <MobileDatePicker
                format="MMM yyyy"
                margin="dense"
                inputVariant="outlined"
                fullWidth={true}
                label={label}
                views={['month', 'year']}
                value={value}
                onChange={fecha => onChange(fecha === '' ? null : fecha)}
                slotProps={{
                    textField: { 
                        fullWidth: true,
                        sx: {
                            '& .MuiInputBase-root': {
                                backgroundColor: 'white',
                            }
                        }
                    },
                    actionBar: { actions: ['clear', 'cancel', 'accept'] }
                }} />
        </LocalizationProvider>
    </>
}

InputPeriodo.propTypes = {
    label: PropTypes.string.isRequired,
    //value: PropTypes.instanceOf(Date),
    onChange: PropTypes.func.isRequired
}
