import React from 'react'
import { Grid } from '@mui/material'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { InputEntero, InputTexto, InputImporte } from '../../comp/Input'
import { InputFecha } from '../../comp/InputFecha'
import { InputSelect } from '../../comp/InputSelect'
import { FiltroEstadoActivo } from '../../comp/FiltroEstadoActivo'
import { ModalDetail } from '../../comp/ModalDetail'
import { ModalSearch } from '../../comp/ModalSearch'
import { Normal } from '../../comp/Texto'
import { TooltipButton } from '../../comp/TooltipButton'
import { Traduccion } from '../../comp/Traduccion'
import { DataTable } from '../../comp/DataTableSimple'
import { Tipos } from '../../utils/Tipos'
import { agregarTransaccion, listarTransacciones } from '../../services/servClientes'
import { Acciones } from '../../utils/Acciones'

export const Filtros = ({ filtros, isOpen, onSubmit }) => {

    const [numero, setNumero] = React.useState(filtros.numero)
    const [nombre, setNombre] = React.useState(filtros.nombre)
    const [activo, setActivo] = React.useState(filtros.activo)

    const modalSearchMaxWidth = 300;

    const handleSubmit = (res) => {
        onSubmit(res, { numero, nombre, activo })
    }

    React.useEffect(() => {
        if (isOpen) {
            setNumero(filtros.numero)
            setNombre(filtros.nombre)
            setActivo(filtros.activo)
        }
    }, [filtros, isOpen])

    return (<ModalSearch isOpen={isOpen} onSubmit={handleSubmit}>
        <Grid container spacing={2} style={{ maxWidth: modalSearchMaxWidth }}>
            <Grid item xs={12}>
                <InputEntero id='numero_cliente'
                    label={Traduccion('TXT_NUMERO')}
                    value={numero}
                    onChange={e => setNumero(e)}
                    autoFocus />
            </Grid>
            <Grid item xs={12}>
                <InputTexto id='nombre_cliente'
                    label={Traduccion('TXT_NOMBRE')}
                    value={nombre}
                    onChange={e => setNombre(e)} />
            </Grid>
            <Grid item xs={12}>
                <FiltroEstadoActivo id='estado_activo_cliente'
                    value={activo}
                    onChange={setActivo} />
            </Grid>
        </Grid>
    </ModalSearch>)
}

export const Detalle = ({ accion, isOpen, onSubmit, seleccion}) => {

    const [numero, setNumero] = React.useState(null)
    const [nombre, setNombre] = React.useState(null)
    const [topeCredito, setTopeCredito] = React.useState(null)

    const [formSubmited, setFormSubmited] = React.useState(false)

    React.useEffect(() => {
        if (isOpen) {
            setNumero(seleccion.numero)
            setNombre(seleccion.nombre)
            setTopeCredito(seleccion.topeCredito)
            setFormSubmited(false)
        }
    }, [seleccion, isOpen])

    const obligatoriosConValor = () => (nombre && topeCredito ? true : false)

    const handleSubmit = (res) => {
        if (res && !obligatoriosConValor()) {
            setFormSubmited(true)
            return //si intento enviar form y los obligatorios no están completos interrumpo
        }
        let aux = {
            id: seleccion.id,
            numero,
            nombre,
            topeCredito,
            saldo: seleccion.saldo ?? 0,
            activo: seleccion.activo ?? true
        }
        aux.disponible = aux.topeCredito + aux.saldo
        onSubmit(res, aux)
    };

    return <ModalDetail accion={accion} isOpen={isOpen} onSubmit={handleSubmit}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <InputEntero label={Traduccion('TXT_NUMERO')}
                    value={numero}
                    onChange={e => setNumero(e)}
                    validarSubmit={formSubmited}
                    editable={Acciones().SEE !== accion}
                    autoFocus />
            </Grid>
            <Grid item xs={12}>
                <InputTexto label={Traduccion('TXT_NOMBRE')}
                    value={nombre}
                    onChange={e => setNombre(e)}
                    validarSubmit={formSubmited}
                    editable={Acciones().SEE !== accion}
                    requerido />
            </Grid>
            <Grid item xs={12}>
                <InputImporte label={Traduccion('TXT_TOPE_CREDITO')}
                    value={topeCredito}
                    onChange={e => setTopeCredito(e)}
                    validarSubmit={formSubmited}
                    editable={Acciones().SEE !== accion}
                    requerido />
            </Grid>
        </Grid>
    </ModalDetail>
}

export const ModalTransacciones = ({ seleccion = {}, isOpen, onSubmit }) => {

    const tableConfig = {
        headers: ['TXT_FECHA', 'TXT_TIPO_TRANSACCION', 'TXT_IMPORTE', 'TXT_SALDO'],
        types: [Tipos().FECHA, Tipos().DESC, Tipos().IMP, Tipos().IMP],
        access: ['fecha', 'detalle', 'importe', 'saldo']
    }

    const [isTableLoading, setIsTableLoading] = React.useState(false)
    const [tableData, setTableData] = React.useState([])
    const [tableFilters, setTableFilters] = React.useState({
        fechaDesde: null,
        fechaHasta: null,
        pageNumber: 0,
        pageSize: 10,
        clienteId: seleccion.id
    })

    const setFechaDesde = (fecha) => {
        let aux = { ...tableFilters }
        aux.fechaDesde = fecha
        aux.pageNumber = 0
        setTableFilters(aux)
    }

    const setFechaHasta = (fecha) => {
        let aux = { ...tableFilters }
        aux.fechaHasta = fecha
        aux.pageNumber = 0
        setTableFilters(aux)
    }

    const pagSiguiente = () => {
        let aux = { ...tableFilters }
        aux.pageNumber += 1
        setTableFilters(aux)
    }

    const pagAnterior = () => {
        let aux = { ...tableFilters }
        aux.pageNumber -= 1
        setTableFilters(aux)
    }

    const formatDataForTable = (datos) => {
        datos.forEach(d => {
            d.detalle = Traduccion(d.tipoTransaccion.nombre)
        })
        return //agregar funcionalidad si hace falta
    }

    React.useEffect(() => {
        if (isOpen) {
            setTableFilters({
                fechaDesde: null,
                fechaHasta: null,
                pageNumber: 0,
                pageSize: 10,
                clienteId: seleccion.id
            })
        }
    }, [seleccion, isOpen])

    React.useEffect(() => {
        if (tableFilters.clienteId) {
            setIsTableLoading(true)
            listarTransacciones(tableFilters).then(res => {
                formatDataForTable(res.data)
                setTableData(res.data)
                setIsTableLoading(false)
            })
        }
    }, [tableFilters])

    return <ModalDetail isOpen={isOpen} onSubmit={onSubmit} accion={Acciones().SEE} customTitle='TXT_TRANSACCIONES' maxWidth='md'>
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <InputFecha label={Traduccion('TXT_FECHA_DESDE')}
                    value={tableFilters.fechaDesde}
                    onChange={fecha => setFechaDesde(fecha)} />
            </Grid>
            <Grid item xs={6}>
                <InputFecha label={Traduccion('TXT_FECHA_HASTA')}
                    value={tableFilters.fechaHasta}
                    onChange={fecha => setFechaHasta(fecha)} />
            </Grid>
            <Grid item xs={12}>
                <DataTable headers={tableConfig.headers}
                    types={tableConfig.types}
                    access={tableConfig.access}
                    data={tableData}
                    isLoading={isTableLoading}
                    size='small' />
            </Grid><Grid container justifyContent='center' alignItems='center'>
                <Grid item>
                    <TooltipButton label="TXT_ANTERIOR"
                        onClick={pagAnterior}
                        disabled={tableFilters.pageNumber === 0}>
                        <KeyboardArrowLeftIcon />
                    </TooltipButton>
                </Grid>
                <Grid item>
                    <Normal>{Traduccion('TXT_PAGINA_ABREV')}: {tableFilters.pageNumber + 1}</Normal>
                </Grid>
                <Grid item>
                    <TooltipButton label="TXT_SIGUIENTE"
                        onClick={pagSiguiente}
                        disabled={tableData.length < tableFilters.pageSize}>
                        <KeyboardArrowRightIcon />
                    </TooltipButton>
                </Grid>
            </Grid>
        </Grid>

    </ModalDetail>

}


export const ModalAjustes = ({ seleccion = {}, isOpen, onSubmit }) => {

    const [tipoTransaccion, setTipoTransaccion] = React.useState()
    const [importeAjuste, setImporteAjuste] = React.useState()
    const [formSubmited, setFormSubmited] = React.useState()

    const getOpciones = () => {
        let res = [{ label: Traduccion('TXT_AJUSTE_DEUDOR'), value: -4 }]
        res.push({ label: Traduccion('TXT_AJUSTE_ACREEDOR'), value: -5 })
        res.sort((e1, e2) => e1.label > e2.label ? 1 : -1)
        return res;
    }

    const obligatoriosConValor = () => (importeAjuste != null && importeAjuste > 0 ? true : false)
    const saldoCalculado = () => seleccion.saldo + ((tipoTransaccion === -5 ? 1 : -1) * importeAjuste)

    const handleSubmit = (res) => {
        if (!res) {
            onSubmit(res)
            return
        }
        if (!obligatoriosConValor()) {
            setFormSubmited(true)
            return //si intento enviar form y los obligatorios no están completos interrumpo
        }
        let unaTransaccion = {
            cliente: { id: seleccion.id },
            importe: (tipoTransaccion === -5 ? 1 : -1) * importeAjuste,
            tipoTransaccion: { id: tipoTransaccion }
        }
        agregarTransaccion(unaTransaccion).then(res2 => {
            if (res2.errores) {
                alert(Traduccion('MSJ_OPERACION_FALLIDA') + ':\r* ' + res2.errores.map(d => Traduccion(d)).join('\r* '))
                return
            }
            unaTransaccion.id = res2.data.id
            onSubmit(unaTransaccion)
        })
    }

    React.useEffect(() => {
        if (isOpen) {
            setTipoTransaccion(seleccion.saldo < 0 ? -4 : -5)
            setImporteAjuste(0.0)
            setFormSubmited(false)
        }
    }, [seleccion, isOpen])

    return <ModalDetail isOpen={isOpen} onSubmit={handleSubmit} customTitle='TXT_AJUSTAR_SALDO' maxWidth='xs'>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <InputSelect id='tipo_movimiento'
                    label={Traduccion('TXT_TIPO_MOVIMIENTO')}
                    value={tipoTransaccion}
                    onChange={v => setTipoTransaccion(v)}
                    options={getOpciones()}
                    editable={seleccion.saldo === 0}/>
            </Grid>
            <Grid item xs={12}>
                <InputImporte id='importe_movimiento'
                    label={Traduccion('TXT_IMPORTE')}
                    value={importeAjuste}
                    onChange={e => setImporteAjuste(e)}
                    validarSubmit={formSubmited}
                    requerido noCero autoFocus/>
            </Grid>
            <Grid item xs={6}>
                <InputImporte id='saldo_actual'
                    label={Traduccion('TXT_SALDO_ACTUAL')}
                    value={seleccion.saldo}
                    editable={false} />
            </Grid>
            <Grid item xs={6}>
                <InputImporte id='saldo_final'
                    label={Traduccion('TXT_SALDO_FINAL')}
                    value={saldoCalculado()}
                    editable={false} />
            </Grid>
        </Grid>
    </ModalDetail>

}