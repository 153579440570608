import { UrlApi } from '../config/Services'
import { DeleteCall, GetCall, PatchCall, PostCall, PutCall } from './_serviceHelper2'

const serviceUrl = `${UrlApi()}/productos/categorias`

export const listarCategoriasProducto = (filters) => {
    let params = {
        nombre: filters?.nombre,
        activo: filters?.activo,
        pageNumber: filters?.pageNumber,
        pageSize: filters?.pageSize
    }
    return GetCall(serviceUrl, params)
}
export const agregarCategoriaProducto = (producto) => PostCall(serviceUrl, producto)
export const modificarCategoriaProducto = (producto) => PutCall(serviceUrl, producto)
export const borrarCategoriasProducto = (ids) => DeleteCall(serviceUrl, {ids})
export const reactivarCategoriasProducto = (ids) => PatchCall(serviceUrl, {ids})