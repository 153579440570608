import { Grid } from '@mui/material'
import React from 'react'
import { AutocompleteProducto } from '../../comp/AutocompleteProducto'
import { InputCantidad } from '../../comp/Input'
import { ModalDetail } from '../../comp/ModalDetail'
import { Traduccion } from '../../comp/Traduccion'
import { agregarConsumo, listarConsumos, modificarConsumo } from '../../services/servConsumos'
import { ListaProductos } from '../productos/lista'

export const MesaModalConsumos = ({ idMesa, nroMesa, idCab, isOpen, onSubmit }) => {

    const [listaProductos, setListaProductos] = React.useState([])
    const [cantidad, setCantidad] = React.useState(1)
    const [isLoading, setIsLoading] = React.useState(false)
    const [isSubmitting, setIsSubmitting] = React.useState(false)

    const handleSelectProducto = (seleccion) => {
        if (seleccion) {
            let listaAux = listaProductos.map(e => e)
            let linea = listaAux.find(e => e.producto.id === seleccion.id)
            if (linea) { //si la línea existe aumento la cantidad
                if (linea.activo) {
                    linea.cantidad += cantidad
                } else {
                    linea.activo = true
                    linea.cantidad = cantidad
                }
            } else {
                listaAux.push({
                    producto: seleccion, 
                    cantidad: cantidad, 
                    precio: seleccion.precioVenta, 
                    activo: true 
                })
            }
            setListaProductos(listaAux)
            setCantidad(1)
        }
    }

    const handleAumentar = (productoId) => {
        let aux = listaProductos.map(e => e)
        aux.find(unaLinea => unaLinea.producto.id === productoId).cantidad += 1
        setListaProductos(aux)
    }

    const handleDisminuir = (productoId) => {
        let aux = listaProductos.map(e => e)
        let elem = aux.find(unaLinea => unaLinea.producto.id === productoId)
        if (elem.cantidad > 1) {
            elem.cantidad -= 1
            setListaProductos(aux)
        }
    }

    const handleBorrar = (productoId) => {
        let listaAux = listaProductos.map(e=>e)
        //si id > 0 solo lo marco como inactivo, sino lo borro de la lista
        let elemAux = listaAux.find(det => det.producto.id === productoId)
        elemAux.id > 0
            ? elemAux.activo = false
            : listaAux = listaAux.filter(e => e !== elemAux)
        setListaProductos(listaAux)
    }

    const handleModalSubmit = (submit) => {
        if (!submit) { //operación cancelada
            onSubmit(false)
            return
        }
        if (!listaProductos.filter(e => e.activo).length) { //validaciones
            alert(Traduccion('ERR_INGRESE_PRODUCTOS'))
            return
        }
        let auxDetalles = listaProductos.map(e => { return { id: e.id, productoId: e.producto.id, cantidad: e.cantidad, activo: e.activo } })
        let obj = {
            id: idCab,
            mesa: { id: idMesa },
            detalles: auxDetalles
        }
        idCab 
            ? _handleUpdItems(obj)
            : _handleAddItems(obj)
    }

    const _handleAddItems = (obj) => {
        setIsSubmitting(true)
        obj.id = null
        agregarConsumo(obj).then(r => {
            setIsSubmitting(false)
            if (r.errores.length) {
                alert(Traduccion('MSJ_OPERACION_FALLIDA') + ':\r* ' + r.errores.map(d => Traduccion(d)).join('\r* '));
                return
            }
            onSubmit(true, r.data)
        })
    }

    const _handleUpdItems = (obj) => {
        setIsSubmitting(true)
        modificarConsumo(obj).then(r => {
            setIsSubmitting(false)
            if (r.errores.length) {
                alert(Traduccion('MSJ_OPERACION_FALLIDA') + ':\r* ' + r.errores.map(d => Traduccion(d)).join('\r* '));
                return
            }
            onSubmit(true, r.data)
        })
    }

    const getProductosParaMostrar = () => listaProductos.filter(e => e.activo)
    
    React.useEffect(() => {
        if (isOpen) {
            setIsLoading(true)
            listarConsumos({ mesaId: idMesa }).then(res => {
                setListaProductos(res.data)
                setIsLoading(false)
            })
        }
    }, [isOpen, idMesa])

    return <ModalDetail translatedTitle customTitle={Traduccion('TXT_CONSUMOS_MESA_NRO') + ' ' + nroMesa}
            isOpen={isOpen}
            onSubmit={handleModalSubmit}
            maxWidth={'xl'}
            isLoading={isSubmitting}>
        <Grid container spacing={2}>
            <Grid item xs={8}>
                <AutocompleteProducto clearOnSelect autoFocus
                    onSelect={handleSelectProducto}
                    editable={!isLoading && !isSubmitting} />
            </Grid>
            <Grid item xs={4}>
                <InputCantidad label={Traduccion('TXT_CANTIDAD')}
                    value={cantidad}
                    onChange={c => setCantidad(c)}
                    editable={!isLoading && !isSubmitting} />
            </Grid>
        </Grid>
        <ListaProductos data={getProductosParaMostrar()} 
            isLoading={isLoading}
            aumentar={handleAumentar}
            disminuir={handleDisminuir}
            borrar={handleBorrar}/>
    </ModalDetail>
}