import React from 'react'
import {IconButton,
        Tooltip
        }  from '@mui/material'
import { Traduccion } from './Traduccion'

/**
 * @param {Component} children es el componente del icono, ej: <AddIcon/>
 * @param {String} label es el texto del boton
 * @param {Function} onClick el evento a disparar cuando se presiona
 * @returns {Component}
 */
export const TooltipButton = ({children, id, label, onClick, disabled = false}) => {
    return (<Tooltip title={Traduccion(label)}>
        <span>
            <IconButton id={id && id} aria-label={Traduccion(label)} onClick={onClick} disabled={disabled}>
                {children}
            </IconButton>
        </span>
    </Tooltip>)
}