import React from "react"
import { DataTable } from "../../../comp/DataTable"
import { ModalDelete } from "../../../comp/ModalDelete"
import { Traduccion } from "../../../comp/Traduccion"
import { listarTiposCobros, agregarTipoCobro, modificarTipoCobro, borrarTipoCobro, reactivarTipoCobro } from "../../../services/servTiposCobros"
import { Acciones } from "../../../utils/Acciones"
import { Tipos } from "../../../utils/Tipos"
import { Detalle } from "./contenido"

export const FormasDeCobro = () => {

    const [detailAction, setDetailAction] = React.useState(Acciones.SEE)
    const [isDelReacOpened, setIsDelReacOpened] = React.useState(false)
    const [isDetailOpened, setIsDetailOpened] = React.useState(false)

    const [isTableLoading, setIsTableLoading] = React.useState(false)
    const [tableData, setTableData] = React.useState([])
    const [tableSelection, setTableSelection] = React.useState([])

    const handleDetailOpen = (action, selection) => {
        setDetailAction(action)
        if ([Acciones().DEL, Acciones().REAC].includes(action)) {
            setTableSelection(selection ? tableData.filter(e => selection.indexOf(e.id) > -1) : [])
            setIsDelReacOpened(true)
        } else {
            setTableSelection(selection ? tableData.filter(e => e.id === selection) : [{}])
            setIsDetailOpened(true)
        }
    }

    const handleDetailSubmit = (submitted, obj) => {
        if (!submitted) {
            setIsDetailOpened(false)
            setIsDelReacOpened(false)
            return
        }
        switch (detailAction) {
            case Acciones().ADD:
                agregarTipoCobro(obj).then(data => {
                    if (data.errores) {
                        alert(Traduccion('MSJ_OPERACION_FALLIDA') + ':\r* ' + data.errores.map(d => Traduccion(d.codigo)).join('\r* '))
                        return
                    }
                    obj.id = data;
                    obj.activo = true;
                    tableData.unshift(obj)
                    setTableSelection([])
                    setIsDetailOpened(false)
                })
                break
            case Acciones().EDIT:
                obj.id = tableSelection[0].id
                modificarTipoCobro(obj).then(data => {
                    if (data.errores) {
                        alert(Traduccion('MSJ_OPERACION_FALLIDA') + ':\r* ' + data.errores.map(d => Traduccion(d.codigo)).join('\r* '))
                        return
                    }
                    tableSelection[0].descripcion = obj.descripcion
                    setIsDetailOpened(false)
                    setTableSelection([])
                })
                break
            case Acciones().REAC:
                reactivarTipoCobro(tableSelection.map(e=>e.id)).then(data => {
                    if (data.errores) {
                        alert(Traduccion('MSJ_OPERACION_FALLIDA') + ':\r* ' + data.errores.map(d => Traduccion(d.codigo)).join('\r* '))
                        return
                    }
                    tableSelection.forEach(e => { e.activo = true })
                    setIsDelReacOpened(false)
                    setTableSelection([])
                })
                break
            case Acciones().DEL:
                borrarTipoCobro(tableSelection.map(e=>e.id)).then(data => {
                    if (data.errores) {
                        alert(Traduccion('MSJ_OPERACION_FALLIDA') + ':\r* ' + data.errores.map(d => Traduccion(d.codigo)).join('\r* '))
                        return
                    }
                    tableSelection.forEach(e => { e.activo = false })
                    setIsDelReacOpened(false)
                    setTableSelection([])
                })
                break
            default: return
        }
    }

    const formatDataForTable = (datos) => {
        datos.forEach(e => {
            e.descripcion = e.id > 0 ? e.descripcion : Traduccion(e.descripcion)
        })
        datos.sort((e1, e2) => e1.descripcion > e2.descripcion ? 1 : -1)
    }

    React.useEffect(() => {
        setIsTableLoading(true)
        listarTiposCobros() //no paso filtros
            .then(data => { //a futuro usar data.objetoOperado
                formatDataForTable(data)
                setTableData(data)
                setIsTableLoading(false)
            })
    }, [])

    return <div>
        <DataTable title='TXT_FORMAS_DE_COBRO'
            headers={['TXT_NOMBRE']}
            types={[Tipos().DESC]}
            access={['descripcion']}
            data={tableData}
            isLoading={isTableLoading}
            rowId="id"
            openDetail={handleDetailOpen}
            hasAdd hasEdit hasReac hasDel />
        <Detalle accion={detailAction}
            isOpen={isDetailOpened}
            onSubmit={handleDetailSubmit}
            seleccion={tableSelection[0]} />
        <ModalDelete isOpen={isDelReacOpened}
            seleccion={tableSelection.map(e => e.descripcion)}
            action={detailAction}
            onSubmit={handleDetailSubmit} />
    </div>
}