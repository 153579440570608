import React from 'react';
import { Grid } from '@mui/material'

import { ResumenCierre } from './resumenCierre'

import { Acciones } from '../../utils/Acciones'

import { AutocompleteUsuario } from '../../comp/AutocompleteUsuario'
import { InputImporte } from '../../comp/Input'
import { InputFecha } from '../../comp/InputFecha'
import { InputSelect } from '../../comp/InputSelect'
import { ModalSearch } from '../../comp/ModalSearch'
import { ModalDetail } from '../../comp/ModalDetail'
import { Traduccion } from '../../comp/Traduccion'

import { UIParams } from '../../config/UIParams'

import { Acceso, NivelAcceso } from '../../utils/Seguridad'

//Si accionSobreCaja = 0 es info, 1 apertura, 2 cierre, 3 reapertura++++

export const Detalle = ({isOpen, onSubmit, seleccion, isSubmiting}) => {
    
    const [id, setId] = React.useState(null)
    const [puntoDeVenta, setPuntoDeVenta] = React.useState(1)
    const [saldoApertura, setSaldoApertura] = React.useState(0.0)
    const [accion, setAccion] = React.useState(Acciones().SEE)

    const puedeAbrir = () => Acceso('ViewCajas', NivelAcceso().AGREGAR)
    const puedeCerrar = () => Acceso('ViewCajas', NivelAcceso().EDITAR)
    const puedeReabrir = () => Acceso('ViewCajas', NivelAcceso().BORRAR_REINSERTAR)
    
    React.useEffect(() => {
        if (isOpen) {
            let acc = Acciones().SEE;
            if (!seleccion?.id) {
                if (puedeAbrir()) {
                    acc = Acciones().ADD;
                }
            } else {
                if (seleccion.activo) {
                    if (puedeCerrar()) {
                        acc = Acciones().EDIT;
                    }
                } else {
                    if (puedeReabrir()) {
                        acc = Acciones().REAC;
                    }
                }
            }
            setAccion(acc);
            setId(seleccion?.id);
            setPuntoDeVenta(seleccion?.puntoDeVenta ?? 1);
            setSaldoApertura(seleccion?.saldoApertura);
        }
    }, [seleccion, isOpen]);
    
    const reloadResumenCierre = () => {
        return Acciones().ADD !== accion && isOpen;
    };
    
    const _handleSubmit = (res) => {
        var aux = {
            id: id,
            puntoDeVenta: {id: puntoDeVenta},
            saldoApertura: saldoApertura
        };
        onSubmit(res, aux);
    };
    
    let titulo = '';
    switch (accion) {
        case Acciones().ADD: titulo = 'TXT_ABRIR_CAJA'; break;
        case Acciones().EDIT: titulo = 'TXT_CERRAR_CAJA'; break;
        case Acciones().REAC: titulo = 'TXT_REABRIR_CAJA'; break;
        case Acciones().SEE: titulo = 'TXT_DET_CIERRE_CAJA'; break;
        default: titulo = 'TXT_ABRIR_CAJA';
    }

    return <>
        <ModalDetail accion={accion} isOpen={isOpen} onSubmit={_handleSubmit} customTitle={titulo} isLoading={isSubmiting}>
            <Grid container spacing={2}>
            { Acciones().ADD === accion &&
                <>
                <Grid item xs={12}>
                    <InputSelect id='puntoDeVentaAbrirCaja'
                                    label={Traduccion('TXT_PUNTO_DE_VENTA')}
                                    value={puntoDeVenta}
                                    onChange={v => setPuntoDeVenta(v)}
                                    options={[
                                        {label: '00001', value: 1}
                                    ]}/>
                </Grid>
                <Grid item xs={12}>
                    <InputImporte id='saldoAperturaAbrirCaja'
                                    label={Traduccion('TXT_SALDO')}
                                    value={saldoApertura}
                                    onChange={e => setSaldoApertura(e)}
                                    autoFocus={true}/>
                </Grid>
                </>
            }
            { (Acciones().ADD !== accion && seleccion) &&
                <Grid item xs={12}>
                    <ResumenCierre caja={seleccion} reload={reloadResumenCierre()}/>
                </Grid>
            }
            </Grid>
        </ModalDetail>
    </>
}

//------------------

export const Filtros = ({values, isOpen, onSubmit}) => {

    const [user, setUser] = React.useState(values.user);
    const [desde, setDesde] = React.useState(values.desde);
    const [hasta, setHasta] = React.useState(values.hasta);
    
    const puedeBuscarUsuario = Acceso('ViewCajas', NivelAcceso().CONTROLAR_TODO);

    const _handleSubmit = (submit) => {
        if (!submit) {
            setUser(values.user);
            setDesde(values.desde);
            setHasta(values.hasta);
        }
        onSubmit(submit, {user: user, desde: desde, hasta: hasta});
    };

    const _handleSelectUsuario = (seleccion) => {
        seleccion ? setUser(seleccion) : setUser('');
    };

    return <ModalSearch isOpen={isOpen} onSubmit={res => _handleSubmit(res)}>
        <Grid container spacing={2} 
              style={{maxWidth: UIParams().modalSearchMaxWidth}}>
        { puedeBuscarUsuario &&
            <Grid item xs={12}>
                <AutocompleteUsuario autoFocus
                                     seleccion={user}
                                     onSelect={_handleSelectUsuario}/>
            </Grid>
        }
            <Grid item xs={12}>
                <InputFecha label={Traduccion('TXT_FECHA_DESDE')} 
                            value={desde}
                            onChange={fDesde => setDesde(fDesde)}/>
            </Grid>
            <Grid item xs={12}>
                <InputFecha label={Traduccion('TXT_FECHA_HASTA')} 
                            value={hasta}
                            onChange={fHasta => setHasta(fHasta)}/>
            </Grid>
        </Grid>
    </ModalSearch>;
};