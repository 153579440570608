import React from 'react'
import { Grid } from '@mui/material'
import { InputTexto, InputCodigo } from '../../comp/Input'
import { InputSelect as SelectCategorias } from './categorias/InputSelect'
import { ModalSearch } from '../../comp/ModalSearch'
import { FiltroEstadoActivo } from '../../comp/FiltroEstadoActivo'
import { Traduccion } from '../../comp/Traduccion'
import { listarCategoriasProducto } from '../../services/servCategoriasProductos'

export const Filtros = ({filtros = {codigo: null, nombre: '', categoriaId: null, activo: true}, 
    isOpen, onSubmit}) => {

    const [codigo, setCodigo] = React.useState(filtros.codigo)
    const [nombre, setNombre] = React.useState(filtros.nombre)
    const [categoriaId, setCategoriaId] = React.useState(filtros.categoriaId)
    const [activo, setActivo] = React.useState(filtros.activo)
    const [listaCategorias, setListaCategorias] = React.useState([])
    const [isLoadingCategorias, setIsLoadingCategorias] = React.useState(false)

    const modalSearchMaxWidth = 300

    const _handleSubmit = (res) => {
        if (!res) {
            setCodigo(filtros.codigo)
            setNombre(filtros.nombre)
            setCategoriaId(filtros.categoriaId)
            setActivo(filtros.activo)
        }
        onSubmit(res, {codigo, nombre, categoriaId, activo})
    }

    //inicializo los comps de primera carga
    React.useEffect(() => {
        setIsLoadingCategorias(true)
        listarCategoriasProducto().then(res => {
            setListaCategorias(res.data)
            setIsLoadingCategorias(false)
        })
    }, [])

    React.useEffect(() => {
        setCodigo(filtros.codigo)
        setNombre(filtros.nombre)
        setCategoriaId(filtros.categoriaId)
        setActivo(filtros.activo)
    }, [filtros])

    return (<ModalSearch isOpen={isOpen} onSubmit={_handleSubmit}>
        <Grid container spacing={2} style={{maxWidth: modalSearchMaxWidth}}>
            <Grid item xs={12}>
                <InputCodigo id='codigo_producto'
                             label={Traduccion('TXT_CODIGO')}
                             value={codigo}
                             onChange={e => setCodigo(e)}
                             digits={18}
                             autoFocus/>
            </Grid>
            <Grid item xs={12}>
                <InputTexto id='descripcion_producto'
                            label={Traduccion('TXT_NOMBRE')}
                            value={nombre}
                            onChange={e => setNombre(e)}/>
            </Grid>
            <Grid item xs={12}>
                <SelectCategorias id='categoria_producto'
                                  actualizar={isOpen}
                                  categoriaId={categoriaId} 
                                  onChange={v => setCategoriaId(v)}
                                  listaCategorias={listaCategorias}
                                  isLoading={isLoadingCategorias}/>
            </Grid>
            <Grid item xs={12}>
                <FiltroEstadoActivo id='estado_activo_producto'
                                    value={activo}
                                    onChange={setActivo}/>
            </Grid>
        </Grid>
    </ModalSearch>)
}