import React from 'react'
import { Button, Card, CardActions, CardContent, CardHeader } from '@mui/material'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import AssignmentIcon from '@mui/icons-material/Assignment'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'

import { Traduccion } from '../../comp/Traduccion'
import { Titulo } from '../../comp/Texto'
import { MoreOptionsMenu } from '../../comp/MoreOptionsMenu'
import { MesaModalCierre } from './mesaModalCierre'
import { MesaModalCobro } from './mesaModalCobro'
import { MesaModalConsumos } from './mesaModalConsumos'

const styles = {
    cardContent: {
        textAlign: 'center',
        fontSize: '4rem'
    },
    cardAction: {
        marginLeft: 'auto'
    }
}

export const MesaCard = ({ idMesa, nroMesa, mesaOcupada }) => {

    const [openModalConsumos, setOpenModalConsumos] = React.useState(false)
    const [openModalCierre, setOpenModalCierre] = React.useState(false)
    const [openModalCobrar, setOpenModalCobrar] = React.useState(false)
    const [estaOcupada, setEstaOcupada] = React.useState(mesaOcupada)

    const modalConsumosSubmited = (res, obj) => {
        if (res) {
            setEstaOcupada(obj)
        }
        setOpenModalConsumos(false)
    }

    const onClickVerCuenta = () => setOpenModalCierre(true)
    const onClickCobrar = () => setOpenModalCobrar(true)

    const modalCierreSubmited = () => setOpenModalCierre(false)
    const modalCobrarSubmited = (res) => {
        setOpenModalCobrar(false)
        if (res) {
            setEstaOcupada(false)
        }
    }

    const getMoreActionsConfig = (estaOcupada) => {
        let config = [
            {
                icon: <AssignmentIcon />,
                text: Traduccion('TXT_VER_CUENTA'),
                action: onClickVerCuenta
            },
            {
                icon: <AttachMoneyIcon />,
                text: Traduccion('TXT_COBRAR'),
                action: onClickCobrar
            }
        ]
        return config
    }

    return <div>
        <Card>{
            estaOcupada
                ? <CardHeader avatar={<CancelOutlinedIcon color={'error'} />} title={Traduccion('TXT_OCUPADA')} action={<MoreOptionsMenu options={getMoreActionsConfig()} />} />
                : <CardHeader avatar={<CheckCircleOutlineIcon color={'primary'} />} title={Traduccion('TXT_DISPONIBLE')} action={<MoreOptionsMenu options={getMoreActionsConfig()} />} />
        }
            <CardContent>
                <Titulo sx={styles.cardContent}>{nroMesa}</Titulo>
            </CardContent>
            <CardActions>
                <Button onClick={() => setOpenModalConsumos(true)}
                    color='primary'
                    variant='contained'
                    sx={styles.cardAction}>
                    {Traduccion('TXT_CONSUMOS')}
                </Button>
            </CardActions>
        </Card>
        <MesaModalConsumos idMesa={idMesa} nroMesa={nroMesa} idCab={estaOcupada} isOpen={openModalConsumos} onSubmit={modalConsumosSubmited} />
        <MesaModalCierre idMesa={idMesa} nroMesa={nroMesa} isOpen={openModalCierre} onSubmit={modalCierreSubmited} />
        <MesaModalCobro idMesa={idMesa} nroMesa={nroMesa} idCab={estaOcupada} isOpen={openModalCobrar} onSubmit={modalCobrarSubmited} />
    </div>
}