import React from 'react'
import {
    CardHeader,
    Grid
} from '@mui/material'

import { Tabs } from '../comp/Tabs'
import { PuntosDeVenta } from './cajas/puntosDeVenta/contenedor'
import { CategoriasProductos } from './productos/categorias/contenedor'
import { Traduccion } from '../comp/Traduccion'
import { FormasDeCobro } from './cajas/formasDeCobro/contenedor'
import { MotivosDeMovimiento } from './cajas/movimientos/motivos/contenedor'
import { Acceso, NivelAcceso } from '../utils/Seguridad'


export const Configuraciones = () => {

    const check = (view) => Acceso(view, NivelAcceso().CONTROLAR_TODO)

    /* A la altura completa de la pantalla le resto:
     * - 64px de la AppBar
     * - 16px de margin superior y los 16px de inferior de Main
     * - 16px de margin superior y los 16px de inferior del CardHeader
     * - 32px del tamaño del header
     * Así la línea que divide las pestañas del contenido llega hasta el final
     */

    return <>
        <CardHeader title={Traduccion('ViewConfiguraciones')} />
        <Tabs ariaLabel='Pestañas'
            height='calc(100vh - 64px - 36px - 36px - 32px)'
            >
            <Grid container spacing={2} label={Traduccion('ViewCajas')} show={check('ViewCajas')}>
                <Grid item xs={12} sm={'auto'}><FormasDeCobro /></Grid>
                <Grid item xs={12} sm={'auto'}><MotivosDeMovimiento /></Grid>
                { false && <Grid item xs={12} sm={'auto'}><PuntosDeVenta/></Grid> }
            </Grid>
            <Grid container spacing={2} label={Traduccion('ViewProductos')} show={check('ViewProductos')}>
                <Grid item xs={12} sm={'auto'}><CategoriasProductos /></Grid>
            </Grid>
            <Grid container spacing={2} label={Traduccion('ViewUsuarios')} show={false}>
                <Grid item xs={12}>Registrar usuarios con su correo y asignarles roles, mostrando resumen de lo que va a poder hacer, darlos de baja invalidando el token, etc.</Grid>
            </Grid>
            <Grid container spacing={2} label='Negocio' show={false}>
                <Grid item xs={12}>Este es para modificar los datos de la empresa: Razón Social, Nombre Fantasía, CUIT, etc.</Grid>
            </Grid>
        </Tabs>
    </>
}
