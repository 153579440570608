import React from 'react'
import { Table,
        TableBody,
        TableHead,
        TableRow
        }  from '@mui/material'
import { CellBody, CellHead, CellFoot, CellFootTotal, EmptyRow, LoadingRows } from './DataTableComp'

const colspan = (headers) => {
    let span = headers.length
    return span
}

export const DataTable = ({headers, headsTraducidos = false, types, access, data = [], isLoading = false, totalizar = 0, size}) => {
    return (
            <Table size={size}>
                    <TableHead>
                        <TableRow>
                            { headers.map((header, idx) =>
                                <CellHead key={idx} tipo={types[idx]} traducir={!headsTraducidos}>{header}</CellHead>
                                                        )
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { isLoading && <LoadingRows span={colspan(headers)}/> }
                        { !isLoading && data.length === 0 && <EmptyRow span={colspan(headers)}/> }
                        { !isLoading && data.length > 0 &&
                                    data.map((obj, index) => 
                                         <TableRow key={index}>
                                            {access.map((ruta, idx) => 
                                                <CellBody key={idx} tipo={types[idx]} ruta={ruta}>{obj}</CellBody>
                                            )}
                                        </TableRow>
                                    )}
                        { totalizar < 0 && data.length > 0 &&
                                <TableRow>
                                    <CellFootTotal span={headers.length + totalizar}/>
                                    {
                                        access.map((ruta, idx) => (
                                            idx + 1 > access.length + totalizar &&
                                                <CellFoot key={idx} tipo={types[idx]} ruta={ruta}>{data}</CellFoot>
                                        ))
                                    }
                                </TableRow>
                        }
                    </TableBody>
                </Table>
                )
}