import { GetCall, PostCall, PutCall } from './_serviceHelperCor'

const serviceUrl = '/cajas/movimientos/motivos';

export const listarMotivosMovimiento = (filters) => {
    var params = [
        { 'key': 'activo', 'val': filters.activo },
        { 'key': 'incluirFormasCobro', 'val': filters.incluirFormasCobro }
    ];
    return GetCall(serviceUrl, params);
};

export const agregarMotivosMovimiento = (obj) => {
    return PostCall(serviceUrl, obj).then(d => {return {errores: d.listaErrores, entidad: d.objetoOperado}})
}

export const modificarMotivosMovimiento = (obj) => {
    return PutCall(serviceUrl, obj).then(d => {return {errores: d.listaErrores, entidad: d.objetoOperado}})
}

export const borrarMotivosMovimiento = (listaIds) => {
    return PutCall(serviceUrl+'/borrar', listaIds).then(d => {return {errores: d.listaErrores, entidad: d.objetoOperado}})
}

export const reactivarMotivosMovimiento = (listaIds) => {
    return PutCall(serviceUrl+'/reactivar', listaIds).then(d => {return {errores: d.listaErrores, entidad: d.data}})
}