import React from "react"
import { Grid } from '@mui/material'
import { InputTexto } from "../../../../comp/Input"
import { InputSelect } from "../../../../comp/InputSelect"
import { ModalDetail } from "../../../../comp/ModalDetail"
import { Traduccion } from "../../../../comp/Traduccion"
import { listarTiposCobros } from "../../../../services/servTiposCobros"

export const Detalle = ({ accion, isOpen, onSubmit, seleccion = {} }) => {

    const [nombre, setNombre] = React.useState(seleccion.nombre)
    const [signo, setSigno] = React.useState(seleccion.signo)
    const [activo, setActivo] = React.useState(seleccion.activo)
    const [formasDeCobro, setFormasDeCobro] = React.useState([])
    const [formSubmited, setFormSubmited] = React.useState(false)

    const [formasDeCobroActivas, setFormasDeCobroActivas] = React.useState([])

    const obligatoriosConValor = () => nombre !== undefined && nombre !== null && nombre.length &&
        formasDeCobro !== undefined && formasDeCobro != null && formasDeCobro.length

    const handleSubmit = (res) => {
        if (res && !obligatoriosConValor()) {
            setFormSubmited(true)
            return //si intento enviar form y los obligatorios no están completos interrumpo
        }
        onSubmit(res, {
            nombre: nombre,
            signo: signo,
            activo: activo,
            formasDeCobro: formasDeCobroActivas.filter(f => formasDeCobro.includes(f.id))
        });
    }

    const formatOpcionesFormasDeCobro = () => {
        return formasDeCobroActivas
            .map(f => { return { label: f.descripcion, value: f.id } })
    }

    React.useEffect(() => {
        if (isOpen) {
            setNombre(seleccion.nombre)
            setSigno(seleccion.signo ? seleccion.signo : 1)
            setActivo(seleccion.activo)
            setFormasDeCobro(seleccion.formasDeCobro ? seleccion.formasDeCobro.map(e => e.id) : [])
            setFormSubmited(false)
            if (!formasDeCobroActivas.length) {
                listarTiposCobros({ estadoActivo: true })
                    .then(data => {
                        data.forEach(e => e.descripcion = e.id < 1 ? Traduccion(e.descripcion) : e.descripcion)
                        data.sort((e1, e2) => e1.descripcion > e2.descripcion ? 1 : -1)
                        setFormasDeCobroActivas(data)
                    })
            }
        }
    }, [seleccion, isOpen, formasDeCobroActivas]);

    return <ModalDetail accion={accion} isOpen={isOpen} onSubmit={handleSubmit}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <InputTexto label={Traduccion('TXT_NOMBRE')}
                    value={nombre}
                    onChange={e => setNombre(e)}
                    validarSubmit={formSubmited}
                    requerido autoFocus />
                <InputSelect label={Traduccion('TXT_TIPO_MOVIMIENTO')}
                    value={signo}
                    onChange={v => setSigno(v)}
                    options={[{ label: Traduccion('TXT_INGRESO'), value: 1 }, { label: Traduccion('TXT_EGRESO'), value: -1 }]} />
                <InputSelect label={Traduccion('TXT_TIPO_COBRO')}
                    value={formasDeCobro}
                    onChange={v => setFormasDeCobro(v)}
                    isLoading={!formasDeCobroActivas.length}
                    options={formatOpcionesFormasDeCobro()}
                    validarSubmit={formSubmited}
                    multiple requerido />
            </Grid>
        </Grid>
    </ModalDetail>
}