import React, {Component} from 'react';
import Snackbar from '@mui/material/Snackbar';
import { Alert, AlertTitle } from '@mui/material';
import PropTypes from 'prop-types';
import {Traduccion} from './Traduccion';

export class Messages extends Component {

    state = {open: true};

    _handleClose = (e) => {
        this.setState({open: false});
        this.props.onClose('');
    }

    _getSeverity(stringMsj) {
        if (typeof this.props.severity !== 'undefined') {
            return this.props.severity;
        }
        switch (stringMsj.substring(0, 3)) {
            case "ERR": return "error";
            case "SUC": return "success";
            case "WRN": return "warning";
            case "INF": return "info";
            default: return "info";
        }
    }

    render() {
        const {title, text} = this.props;

        return (
                <Snackbar open={this.state.open} 
                          autoHideDuration={5000} 
                          onClose={this._handleClose}
                          anchorOrigin={{'vertical': 'top', 'horizontal': 'right'}}>
                    <Alert elevation={6} 
                           variant="filled"
                           onClose={this._handleClose}
                           severity={this._getSeverity(text)}>
                        {title &&
                                    <AlertTitle>{Traduccion(title)}</AlertTitle>
                        }
                        {Traduccion(text)}
                    </Alert>
                </Snackbar>
                );
    }

}

Messages.propTypes = {
    severity: PropTypes.oneOf(['success', 'info', 'warning', 'error']),
    title: PropTypes.string,
    text: PropTypes.string
};