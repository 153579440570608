import { Paper } from '@mui/material'
import React from "react"
import { InputImporte } from "../../../comp/Input"
import { LoadingRect } from "../../../comp/LoadingComp"
import { Normal } from "../../../comp/Texto"
import { Traduccion } from "../../../comp/Traduccion"

import { listarTiposCobros } from "../../../services/servTiposCobros"

export const DetalleFormasDeCobro = ({ dataChangeCallback, isLoadingCallback }) => {

    const [primeraCarga, setPrimeraCarga] = React.useState(true)
    const [isLoading, setIsLoading] = React.useState(false)
    const [formasDeCobro, setFormasDeCobro] = React.useState([])

    const updateFormaPago = (importe, id) => {
        let aux = formasDeCobro.map(e => e)
        aux.find(e => e.tipoCobro.id === id).importe = importe
        setFormasDeCobro(aux)
    }

    //efectos para disparar los callbacks cuando son actualizados los valores
    React.useEffect(() => {dataChangeCallback(formasDeCobro)}, [dataChangeCallback, formasDeCobro])
    React.useEffect(() => {isLoadingCallback(isLoading)}, [isLoadingCallback, isLoading])
    
    React.useEffect(() => {
        if (primeraCarga) {
            setPrimeraCarga(false)
            setIsLoading(true)
            listarTiposCobros({ estadoActivo: true }).then(data => {
                let aux = []
                data.forEach(e => {
                    aux.push({ 'tipoCobro': e, importe: 0 })
                })
                setFormasDeCobro(aux)
                setIsLoading(false)
            })
        }
    }, [primeraCarga])

    return <Paper style={{padding: '1em'}}>
        <Normal>{Traduccion('TXT_FORMAS_DE_PAGO')}</Normal>
        {isLoading
            ? <LoadingRect rows={3} />
            : formasDeCobro.length
                ? formasDeCobro.map(elem => (
                    <InputImporte key={elem.tipoCobro.id}
                        id={elem.tipoCobro.id}
                        label={elem.tipoCobro.descripcion}
                        value={elem.importe}
                        onChange={updateFormaPago}
                        autoFocus={elem.tipoCobro.id === 1} />
                ))
                : Traduccion('MSJ_NO_HAY_REGISTROS')
        }
    </Paper>
}