import React from 'react'
import { Button, Typography }  from '@mui/material'
import { Acciones } from '../utils/Acciones'
import { Modal } from './Modal'
import { Traduccion } from './Traduccion'

export const ModalDelete = ({action, isOpen = false, onSubmit, seleccion = []}) => {
    
    const getColor = () => action === Acciones().DEL ? 'secondary' : 'primary'
    const getTextoBtn = () => action === Acciones().DEL ? 'TXT_BORRAR' : 'TXT_REACTIVAR'
    const getTitle = () => action === Acciones().DEL ? 'MSJ_SEGURO_BORRAR' : 'MSJ_SEGURO_REACTIVAR'

    const actions = (
            <div>
                <Button onClick={e => onSubmit(false)} color={getColor()}>
                    {Traduccion('TXT_CANCELAR')}
                </Button>
                <Button onClick={e => onSubmit(true)} color={getColor()} variant="contained">
                    {Traduccion(getTextoBtn())}
                </Button>
            </div>
            )

    return (
            <Modal isOpen={isOpen}
                   actions={actions}
                   title={Traduccion(getTitle())}
                   onClose={e => onSubmit(false)}
                   maxWidth="xs"
                   >
                {seleccion.map((e, idx) => {
                        return <Typography key={idx}>* {e}</Typography>
                    })}
            </Modal>
            )
}