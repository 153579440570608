import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export function Modal({isOpen, onClose, title, actions, maxWidth, children}) {
    return (
            <Dialog
                open={isOpen}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-content"
                maxWidth={maxWidth}
                >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent id="alert-dialog-content">
                    {children}
                </DialogContent>
                <DialogActions>
                    {actions}
                </DialogActions>
            </Dialog>
            );
}