import React from 'react'

import { DataTable } from '../../../comp/DataTableSimple'
import { ModalDetail } from '../../../comp/ModalDetail'
import { Traduccion } from '../../../comp/Traduccion'

import { Acciones } from '../../../utils/Acciones'
import { Tipos } from '../../../utils/Tipos'

import { listarModificaciones } from '../../../services/servCajas1'

const txtEsApertura = Traduccion('TXT_APERTURA');
const txtEsCierre = Traduccion('TXT_CIERRE');

export const HistoricoCaja = ({idCaja, mostrar, handleClose}) => {
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    
    React.useEffect(() => {
        if (mostrar) {
            setLoading(true);
            listarModificaciones(idCaja).then(data => {
                data.forEach(d => {
                    d.tipoMovimiento = d.esApertura ? txtEsApertura : txtEsCierre;
                });
                setData(data);
                setLoading(false);
            });
        }
    }, [idCaja, mostrar]);
    
    return (
            <ModalDetail accion={Acciones().SEE} isOpen={mostrar} onSubmit={handleClose} customTitle="TXT_HISTORICO" maxWidth="xl">
                <DataTable headers={['TXT_TIPO_MOVIMIENTO', 'TXT_FECHA', 'TXT_USUARIO']} 
                            types={[Tipos().DESC, Tipos().FECHA, Tipos().DESC]}
                            access={['tipoMovimiento', 'fechaHora', 'usuarioOperacion.user']}
                            data={data}
                            isLoading={loading}/>
            </ModalDetail>
            );
};