import React from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { Traduccion } from './Traduccion'

export const FiltroEstadoActivo = ({value = true, onChange}) => {

    const _onChange = (e) => {
        let val = e.target.value
        onChange(val === 'all' ? null : val)
    }

    return (
            <FormControl variant="outlined" fullWidth margin="dense">
                <InputLabel id="label-filtro-estado">{Traduccion('TXT_ESTADO')}</InputLabel>
                <Select
                    id="filtro-estado"
                    value={value === null ? 'all' : value}
                    onChange={e => _onChange(e)}
                    labelId="label-filtro-estado"
                    label={Traduccion('TXT_ESTADO')}
                    >
                    <MenuItem value="all">{Traduccion('TXT_SELECCION_TODOS')}</MenuItem>
                    <MenuItem value={true}>{Traduccion('TXT_ACTIVOS')}</MenuItem>
                    <MenuItem value={false}>{Traduccion('TXT_INACTIVOS')}</MenuItem>
                </Select>
            </FormControl>)
}