import React from 'react'
import { Checkbox, FormControl, FormHelperText, InputLabel, LinearProgress, MenuItem, Select } from '@mui/material'
import { Traduccion } from '../comp/Traduccion'

export const InputSelect = ({ id, label, value = '', onChange, options = [{ label: '--', value: '' }], isLoading = false, editable = true, multiple = false, requerido = false, validarSubmit = false }) => {

    const getLabelOfSelection = (seleccion) => {
        if (seleccion) {
            if (multiple) {
                return options
                    .filter(e => seleccion.includes(e.value))
                    .map(e => e.label)
                    .join(', ')
            } else {
                return options
                    .filter(opt => opt.value === seleccion)
                    .map(opt => opt.label)
                    .join(', ')
            }
        } else {
            return ''
        }
    }

    const huboError = () => validarSubmit && requerido && (!value || (multiple && !value.length))

    return (
        <FormControl variant="outlined" fullWidth
            margin="dense"
            disabled={isLoading || !editable}
            error={huboError()}
            required={requerido}>
            <InputLabel id="input-label">{label}</InputLabel>
            <Select
                id={id ? id : `IS_${Math.random()}`}
                value={value}
                onChange={e => onChange(e.target.value)}
                labelId="input-label"
                label={label}
                multiple={multiple}
                renderValue={(selected) => getLabelOfSelection(selected)}
            >
                {isLoading
                    ? <MenuItem value={value}>{Traduccion('TXT_CARGANDO')}</MenuItem>
                    : options.map((opt, idx) =>
                        <MenuItem key={idx} value={opt.value}>
                            {multiple && <Checkbox checked={value.indexOf(opt.value) > -1} />}
                            {opt.label}
                        </MenuItem>
                    )
                }
            </Select>
            <FormHelperText>{huboError() ? Traduccion('TXT_REQUERIDO') : ''}</FormHelperText>
            {isLoading && <LinearProgress />}
        </FormControl>)
}