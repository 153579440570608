import React from 'react';
import { Grid } from '@mui/material'
import { InputCantidad, InputCodigo, InputImporte, InputTexto } from '../../comp/Input'
import { InputSelect as SelectCategorias } from './categorias/InputSelect'
import { Traduccion } from '../../comp/Traduccion'
import { ModalDetail } from '../../comp/ModalDetail'
import { Switcher } from '../../comp/Switcher'
import { Acciones } from '../../utils/Acciones'
import { listarCategoriasProducto } from '../../services/servCategoriasProductos';
import { agregarProducto, buscarSiguienteCodigoDisponible, editarProducto } from '../../services/servProductos';
import { Messages } from '../../comp/Messages2';

export const Detalle = ({accion, isOpen, onSubmit, seleccion, verPrecioCompra, verPrecioVenta}) => {

    const [id, setId] = React.useState(null)
    const [codigo, setCodigo] = React.useState(null)
    const [nombre, setNombre] = React.useState(null)
    const [precioCosto, setPrecioCosto] = React.useState(null)
    const [precioVenta, setPrecioVenta] = React.useState(null)
    const [cantidad, setCantidad] = React.useState(null)
    const [conDevolucion, setConDevolucion] = React.useState(false)
    //const [conPesaje, setConPesaje] = React.useState(seleccion.conPesaje)
    const [categoriaId, setCategoriaId] = React.useState(1) //default: 'sin especificar'
    const [formSubmited, setFormSubmited] = React.useState(false)
    const [isSubmitting, setIsSubmitting] = React.useState(false)

    const [categorias, setCategorias] = React.useState([])
    const [isCategoriasLoading, setIsCategoriasLoading] = React.useState(false)

    const [resServicio, setResServicio] = React.useState()
    
    const esEditable = () => accion !== Acciones().SEE
    const obligatoriosConValor = () => (codigo ? true : false) 
        && (nombre ? true : false)
        && (precioCosto != null && precioCosto >= 0 ? true : false)
        && (precioVenta != null && precioVenta >= 0 ? true : false)
        && (cantidad != null && cantidad >= 0 ? true : false)
        && (categoriaId ? true : false)

    const handleSubmit = async (res) => {
        if (!res) return onSubmit()
        //si intento enviar form y los obligatorios no están completos interrumpo
        if (!obligatoriosConValor()) return setFormSubmited(true)
        setResServicio() //inicializo los msjs
        setIsSubmitting(true)
        var aux = {
            id,
            codigo,
            nombre,
            precioCosto,
            precioVenta,
            cantidad,
            conDevolucion,
            conPesaje: false, //hardcodeado hardcoded deprecado deprecated
            categoria: {id: categoriaId},
            activo: seleccion?.activo ?? true
        }
        let resServ = (accion === Acciones().ADD)
            ? await agregarProducto(aux)
            : await editarProducto(aux)
        setResServicio(resServ)
        setIsSubmitting(false)
        if (resServ.status > 200) return
        aux.id = aux.id ?? resServ.data?.id
        onSubmit(aux)
    }

    //inicializo todo lo que solo tiene que hacerse una sola vez
    React.useEffect(() => {
        setIsCategoriasLoading(true)
        listarCategoriasProducto({activo:true}).then(d => {
            setCategorias(d.data)
            setIsCategoriasLoading(false)
        })
    }, [])

    const cargarCodigo = async (selec) => {
        if (!selec?.codigo) {
            let resp = await buscarSiguienteCodigoDisponible()
            setCodigo(resp.data)
        } else {
            setCodigo(selec.codigo)
        }
    }

    React.useEffect(() => {
        if (isOpen) {
            setId(seleccion?.id)
            cargarCodigo(seleccion)
            setNombre(seleccion?.nombre)
            setPrecioCosto(seleccion?.precioCosto)
            setPrecioVenta(seleccion?.precioVenta)
            setCantidad(seleccion?.cantidad)
            setConDevolucion(seleccion?.conDevolucion ?? false)
            //setConPesaje(seleccion.conPesaje)
            setCategoriaId(seleccion?.categoria?.id ?? 1) //default: 'sin especificar'
            setFormSubmited(false)
        }
    }, [seleccion, isOpen])

    //inicializo los msjs cada vez que cierro el modal
    React.useEffect(() => {
        if (!isOpen) setResServicio()
    }, [isOpen])
    
    return <>
        <ModalDetail accion={accion} isOpen={isOpen} onSubmit={handleSubmit} isLoading={isSubmitting}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <InputCodigo id='codigo_producto'
                                label={Traduccion('TXT_CODIGO')}
                                value={codigo}
                                onChange={e => setCodigo(e)}
                                digits={18}
                                editable={esEditable()}
                                validarSubmit={formSubmited}
                                requerido
                                autoFocus={true}/>
                </Grid>
                <Grid item xs={12}>
                    <InputTexto id='descripcion_producto'
                                label={Traduccion('TXT_NOMBRE')}
                                value={nombre}
                                onChange={e => setNombre(e)}
                                editable={esEditable()}
                                validarSubmit={formSubmited}
                                requerido/>
                </Grid>
                { verPrecioCompra &&
                        <Grid item xs={6}>
                            <InputImporte id='precio_compra_producto'
                                            label={Traduccion('TXT_COSTO_MIN')}
                                            value={precioCosto}
                                            onChange={e => setPrecioCosto(e)}
                                            editable={esEditable()}
                                            validarSubmit={formSubmited}
                                            requerido/>
                        </Grid>
                }
                { verPrecioVenta &&
                        <Grid item xs={6}>
                            <InputImporte id='precio_venta_producto'
                                            label={Traduccion('TXT_VENTA_MIN')}
                                            value={precioVenta}
                                            onChange={e => setPrecioVenta(e)}
                                            editable={esEditable()}
                                            validarSubmit={formSubmited}
                                            requerido/>
                        </Grid>
                }
                <Grid item xs={6}>
                    <InputCantidad id='stock_producto'
                                    label={Traduccion('TXT_STOCK')}
                                    value={cantidad}
                                    onChange={e => setCantidad(e)}
                                    editable={esEditable()}
                                    validarSubmit={formSubmited}
                                    requerido/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <SelectCategorias id='categoria_producto'
                                        categoriaId={categoriaId}
                                        listaCategorias={categorias}
                                        isLoading={isCategoriasLoading}
                                        onChange={v => setCategoriaId(v)}
                                        editable={esEditable()}
                                        validarSubmit={formSubmited}
                                        requerido/>
                </Grid>
                <Grid item xs={12}>
                    <Switcher id='admite_devolucion_producto'
                                label={Traduccion('TXT_PREGUNTA_ADMITE_DEVOLUCION')}
                                value={conDevolucion}
                                onChange={e => setConDevolucion(e)}
                                editable={esEditable()}/>
                </Grid>
            </Grid>
        </ModalDetail>
        <Messages resServicio={resServicio} accion={accion}/>
    </>
}