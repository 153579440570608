import React from 'react'
import { AutocompleteComp } from './Autocomplete'
import { autocompletarProducto } from '../services/servProductos'

export const AutocompleteProducto = ({id, onSelect, clearOnSelect, autoFocus, seleccion, editable}) => {
    
    const itemLabel = (obj) => obj ? obj.codigo + ' - ' + obj.nombre : ''

    return <AutocompleteComp id={id} serviceCall={autocompletarProducto} responseData='data' label="TXT_PRODUCTO"
                      itemLabel={itemLabel} onSelect={onSelect} clearOnSelect={clearOnSelect} autoFocus={autoFocus} seleccion={seleccion}
                      editable={editable}/>
}