import { GetCall, PostCall, PutCall } from './_serviceHelperMsa'

const serviceUrl = '/consumos'

export const listarConsumos = (filters) => {
    let params
    if (filters) {
        params = [
            { 'key': 'mesaId', 'val': filters.mesaId }
        ]
    }
    return GetCall(serviceUrl, params)
}

export const agregarConsumo = (obj) => {
    return PostCall(serviceUrl, obj)
}

export const modificarConsumo = (listaIds) => {
    return PutCall(serviceUrl, listaIds)
}