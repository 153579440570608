import React from 'react'
import { DataTable } from '../../comp/DataTableV2'
import { Traduccion } from '../../comp/Traduccion'
import {
    listarUsuarios,
    agregarUsuario,
    modificarUsuario,
    borrarUsuarios,
    reactivarUsuarios
} from '../../services/servUsuarios'
import { ModalDelete } from '../../comp/ModalDelete'
import { Acciones } from '../../utils/Acciones'
import { Tipos } from '../../utils/Tipos'
import { Detalle, Filtros, ModalTransacciones } from './contenido'
import ListIcon from '@mui/icons-material/List'

export const Usuarios = () => {

    const tableConfig = {
        title: 'ViewUsuarios',
        headers: ['TXT_APELLIDOS', 'TXT_NOMBRES', 'TXT_CORREO', 'TXT_ROLES'],
        types: [Tipos().DESC, Tipos().DESC, Tipos().DESC, Tipos().DESC],
        access: ['apellidos', 'nombres', 'email', 'roles'],
        rowId: 'id'
    }

    const [detailAction, setDetailAction] = React.useState(Acciones.SEE)
    const [isDelReacOpened, setIsDelReacOpened] = React.useState(false)
    const [isDetailOpened, setIsDetailOpened] = React.useState(false)
    const [isSearchOpened, setIsSearchOpened] = React.useState(false)

    const [isTableLoading, setIsTableLoading] = React.useState(false)
    const [tableData, setTableData] = React.useState([])
    const [tableSelection, setTableSelection] = React.useState([])
    const [tableFilters, setTableFilters] = React.useState({activo: true})

    const [isTransaccionesOpen, setIsTransaccionesOpen] = React.useState(false)
    

    const handleDetailOpen = (action, selection) => {
        setDetailAction(action)
        switch (action) {
            case 'C-TRA':
                setTableSelection(selection ? tableData.filter(e => e.id === selection) : [{}])
                setIsTransaccionesOpen(true)
                break
            case Acciones().DEL:
            case Acciones().REAC:
                setTableSelection(selection ? tableData.filter(e => selection.indexOf(e.id) > -1) : [])
                setIsDelReacOpened(true)
                break
            default:
                setTableSelection(selection ? tableData.filter(e => e.id === selection) : [{}])
                setIsDetailOpened(true)
                break
        }
    }

    const handleDetailSubmit = (submitted, obj) => {
        if (!submitted) {
            setIsDetailOpened(false)
            setIsDelReacOpened(false)
            return
        }
        switch (detailAction) {
            case Acciones().ADD:
                agregarUsuario(obj).then(res => {
                    if (res.errores) {
                        mostrarErroresOperacion(res.errores)
                        return
                    }
                    obj.id = res.data.id;
                    obj.activo = true;
                    obj.disponible = obj.topeCredito
                    tableData.unshift(obj)
                    setTableSelection([])
                    setIsDetailOpened(false)
                })
                break
            case Acciones().EDIT:
                obj.id = tableSelection[0].id
                modificarUsuario(obj).then(res => {
                    if (res.errores) {
                        mostrarErroresOperacion(res.errores)
                        return
                    }
                    Object.keys(obj).forEach(prop => tableSelection[0][prop] = obj[prop])
                    setIsDetailOpened(false)
                    setTableSelection([])
                })
                break
            case Acciones().REAC:
                reactivarUsuarios(tableSelection.map(e => e.id)).then(res => {
                    if (res.errores) {
                        mostrarErroresOperacion(res.errores)
                        return
                    }
                    tableSelection.forEach(e => { e.activo = true })
                    setIsDelReacOpened(false)
                    setTableSelection([])
                })
                break
            case Acciones().DEL:
                borrarUsuarios(tableSelection.map(e => e.id)).then(res => {
                    if (res.errores) {
                        mostrarErroresOperacion(res.errores)
                        return
                    }
                    tableSelection.forEach(e => { e.activo = false })
                    setIsDelReacOpened(false)
                    setTableSelection([])
                })
                break
            default: return
        }
    }

    const mostrarErroresOperacion = (errores) => {
        Array.isArray(errores)
            ? alert(Traduccion('MSJ_OPERACION_FALLIDA') + ':\r* ' + errores.map(d => Traduccion(d.codigo)).join('\r* '))
            : alert(Traduccion('ERR_INESPERADO'))
    }

    const handleSearchSubmit = (submit, filters) => {
        if (submit) {
            setTableFilters(filters);
        }
        setIsSearchOpened(false)
    }

    const defineMoreActions = () => {
        let otherActions = [
            {
                cond: 'singleSelect',
                label: 'TXT_TRANSACCIONES',
                icon: <ListIcon />,
                action: 'C-TRA'
            }
        ]
        return otherActions
    }

    const formatDataForTable = (datos) => {
        return //agregar funcionalidad si hace falta
    }

    React.useEffect(() => {
        setIsTableLoading(true)
        listarUsuarios(tableFilters)
            .then(res => {
                formatDataForTable(res.data)
                setTableData(res.data)
                setIsTableLoading(false)
            })
    }, [tableFilters])

    return <div>
        <DataTable title={tableConfig.title}
            headers={tableConfig.headers}
            types={tableConfig.types}
            access={tableConfig.access}
            rowId={tableConfig.rowId}
            data={tableData}
            isLoading={isTableLoading}
            openDetail={handleDetailOpen}
            openSearch={setIsSearchOpened}
            otherActions={defineMoreActions}
            hasAdd hasEdit hasReac hasDel />
        <Filtros isOpen={isSearchOpened}
                 onSubmit={handleSearchSubmit}
                 filtros={tableFilters}/>
        <Detalle accion={detailAction}
            isOpen={isDetailOpened}
            onSubmit={handleDetailSubmit}
            seleccion={tableSelection[0]} />
        <ModalDelete isOpen={isDelReacOpened}
            seleccion={tableSelection.map(e => e.nombre)}
            action={detailAction}
            onSubmit={handleDetailSubmit} />
        <ModalTransacciones isOpen={isTransaccionesOpen}
            seleccion={tableSelection[0]}
            onSubmit={e => setIsTransaccionesOpen(false)} />
    </div>
}