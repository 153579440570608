import { GetCall, PostCall, PutCall } from './_serviceHelperCor'

const serviceUrl = '/tipos_de_cobro'

export const listarTiposCobros = (filters) => {
    let params;
    if (filters) {
        params = [
            {'key': 'descripcion', 'val': filters.descripcion},
            {'key': 'estadoActivo', 'val': filters.estadoActivo}
        ];
    }
    return GetCall(serviceUrl, params)
};

export const agregarTipoCobro = (obj, responseStatus) => {
    return PostCall(serviceUrl, obj, responseStatus).then(d => {return {errores: d.listaErrores, entidad: d.objetoOperado}})
}

export const modificarTipoCobro = (obj) => {
    return PutCall(serviceUrl, obj).then(d => {return {errores: d.listaErrores, entidad: d.objetoOperado}})
}

export const borrarTipoCobro = (listaIds) => {
    return PutCall(serviceUrl+'/borrar', listaIds).then(d => {return {errores: d.listaErrores, entidad: d.objetoOperado}})
}

export const reactivarTipoCobro = (listaIds) => {
    return PutCall(serviceUrl+'/reactivar', listaIds).then(d => {return {errores: d.listaErrores, entidad: d.objetoOperado}})
}