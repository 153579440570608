import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import { Detalle, Filtros } from './contenido'
import { DatosCaja } from './DatosCaja'

import { DataTable } from '../../../comp/DataTableV2'

import { getCajaOperada } from '../../../config/Sesion'

import { Tipos } from '../../../utils/Tipos'
import { Acciones } from '../../../utils/Acciones'
import { Acceso, NivelAcceso } from '../../../utils/Seguridad'

import { listarMotivosMovimiento } from '../../../services/servCajasMotivosMovimiento'
import { listar } from '../../../services/servCajasMovimientos'
import { Traduccion } from '../../../comp/Traduccion'

const vista = 'ViewMovimientosCajas';

export const Movimientos = () => {

    const [tData, setTData] = React.useState([])
    const [filters, setFilters] = React.useState({caja: getCajaOperada(), motivoId: null})
    const [isSearchOpened, setIsSearchOpened] = React.useState(false)
    const [isDetailOpened, setIsDetailOpened] = React.useState(false)
    const [isTableLoading, setIsTableLoading] = React.useState(true)
    const [seleccion, setSeleccion] = React.useState([])
    const [motivos, setMotivos] = React.useState([])

    const navigate = useNavigate()

    const _handleOpenDetail = (action, selection) => {
        if (action === Acciones().ADD) {
            navigate('/cajas/movimientos/nuevo');
        } else {
            setIsDetailOpened(true)
            setSeleccion(selection ? tData.filter(e => e.id === selection) : {})
        }
    }
    
    const getSelectionForDetail = () => isDetailOpened ? seleccion[0] : {cliente: {}}
    
    const _handleSearchSubmit = (submit, filtros) => {
        if (submit) {
            setFilters(filtros)
        }
        setIsSearchOpened(false)
    }

    const _search = (filtros) => {
        setIsTableLoading(true)
        listar(filtros).then(res => {
            let aux = res.map(e => e)
            aux.forEach(e => {
                e.puntoDeVenta = '00001'
                e.importe = [-2,1].includes(e.motivoMovimiento.id) 
                    ? e.totalVendido
                    : e.totalCobrado * e.motivoMovimiento.signo
                e.motivoMovimiento.nombre = e.motivoMovimiento.id < 0
                    ? Traduccion(e.motivoMovimiento.nombre)
                    : e.motivoMovimiento.nombre
            })
            setTData(aux)
            setIsTableLoading(false)
        })
    }

    //actualiza la tabla cuando cambian los filtros
    React.useEffect(() => {
        _search(filters)
    }, [filters])

    //busca de entrada los motivos de movimiento
    React.useEffect(() => {
        listarMotivosMovimiento({}).then(data => setMotivos(data))
    }, [])

    return <div>
        <Grid container spacing={2}>
            <Grid item xs={12} md={3} lg={2}>
                <DatosCaja datosCaja={filters.caja}
                            handleVolver={() => navigate('/cajas')}/>
            </Grid>
            <Grid item xs={12} md={9} lg={10}>
                <DataTable title={vista}
                            headers={['TXT_TICKET_NRO','TXT_FECHA', 'TXT_MOTIVO', 'TXT_IMPORTE']}
                            types={[Tipos().NUM, Tipos().FECHA, Tipos().DESC, Tipos().IMP]}
                            access={['id', 'fechaHora', 'motivoMovimiento.nombre', 'importe']}
                            data={tData}
                            rowId="id"
                            openSearch={() => setIsSearchOpened(true)}
                            openDetail={_handleOpenDetail}
                            isLoading={isTableLoading}
                            hasAdd={Acceso('ViewCajas', NivelAcceso().AGREGAR) && filters.caja.activo}
                            hasSee/>
            </Grid>
        </Grid>
        <Filtros values={filters}
                 motivos={motivos}
                 isOpen={isSearchOpened}
                 onSubmit={_handleSearchSubmit}/>
        <Detalle ventaId={getSelectionForDetail().id}
                cliente={getSelectionForDetail().cliente}
                vendido={getSelectionForDetail().totalVendido}
                cobrado={getSelectionForDetail().totalCobrado}
                motivo={getSelectionForDetail().motivoMovimiento}
                isOpen={isDetailOpened}
                fechaHora={getSelectionForDetail().fechaHora}
                onSubmit={() => setIsDetailOpened(false)}/>
    </div>
    
}