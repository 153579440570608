import React from 'react';
import { AutocompleteComp } from './Autocomplete';
import { autocompUsuario } from '../services/usuario';

export const AutocompleteUsuario = ({onSelect, clearOnSelect, autoFocus, seleccion}) => {

    const itemLabel = (obj) => obj ? '(' + obj.user + ') - ' + obj.nombre : '';

    return <AutocompleteComp serviceCall={autocompUsuario} label="TXT_USUARIO"
                      itemLabel={itemLabel} onSelect={onSelect} clearOnSelect={clearOnSelect} autoFocus={autoFocus} seleccion={seleccion}
                      />;
};