import React from "react"
import { DataTable } from "../../../../comp/DataTable"
import { ModalDelete } from "../../../../comp/ModalDelete"
import { Traduccion } from "../../../../comp/Traduccion"
import { agregarMotivosMovimiento, borrarMotivosMovimiento, listarMotivosMovimiento, modificarMotivosMovimiento, reactivarMotivosMovimiento } from "../../../../services/servCajasMotivosMovimiento"
import { Acciones } from "../../../../utils/Acciones"
import { Tipos } from "../../../../utils/Tipos"
import { Detalle } from "./contenido"

export const MotivosDeMovimiento = () => {

    const [detailAction, setDetailAction] = React.useState(Acciones().ADD)
    const [isDelReacOpened, setIsDelReacOpened] = React.useState(false)
    const [isDetailOpened, setIsDetailOpened] = React.useState(false)

    const [isTableLoading, setIsTableLoading] = React.useState(false)
    const [tableData, setTableData] = React.useState([])
    const [tableSelection, setTableSelection] = React.useState([])

    const handleDetailOpen = (action, selection) => {
        setDetailAction(action)
        if ([Acciones().DEL, Acciones().REAC].includes(action)) {
            setTableSelection(selection ? tableData.filter(e => selection.indexOf(e.id) > -1) : [])
            setIsDelReacOpened(true)
        } else {
            setTableSelection(selection ? tableData.filter(e => e.id === selection) : [{}])
            setIsDetailOpened(true)
        }
    }

    const handleDetailSubmit = (submitted, obj) => {
        if (!submitted) {
            setIsDetailOpened(false)
            setIsDelReacOpened(false)
            return
        }
        switch (detailAction) {
            case Acciones().ADD:
                agregarMotivosMovimiento(obj).then(data => {
                    if (data.errores) {
                        alert(Traduccion('MSJ_OPERACION_FALLIDA') + ':\r* ' + data.errores.map(d => Traduccion(d.codigo)).join('\r* '))
                        return
                    }
                    obj.id = data.entidad.id;
                    obj.activo = true;
                    tableData.unshift(obj)
                    formatDataForTable([obj])
                    setTableSelection([])
                    setIsDetailOpened(false)
                })
                break
            case Acciones().EDIT:
                obj.id = tableSelection[0].id
                modificarMotivosMovimiento(obj).then(data => {
                    if (data.errores) {
                        alert(Traduccion('MSJ_OPERACION_FALLIDA') + ':\r* ' + data.errores.map(d => Traduccion(d.codigo)).join('\r* '))
                        return
                    }
                    let objOriginal = tableData.find(e => e.id === obj.id)
                    objOriginal.nombre = obj.nombre
                    objOriginal.signo = obj.signo
                    objOriginal.formasDeCobro = obj.formasDeCobro
                    formatDataForTable([objOriginal])
                    setIsDetailOpened(false)
                    setTableSelection([])
                })
                break
            case Acciones().REAC:
                reactivarMotivosMovimiento(tableSelection.map(e => e.id)).then(data => {
                    if (data.errores) {
                        alert(Traduccion('MSJ_OPERACION_FALLIDA') + ':\r* ' + data.errores.map(d => Traduccion(d.codigo)).join('\r* '))
                        return
                    }
                    tableSelection.forEach(e => { e.activo = true })
                    setIsDelReacOpened(false)
                    setTableSelection([])
                })
                break
            case Acciones().DEL:
                borrarMotivosMovimiento(tableSelection.map(e => e.id)).then(data => {
                    if (data.errores) {
                        alert(Traduccion('MSJ_OPERACION_FALLIDA') + ':\r* ' + data.errores.map(d => Traduccion(d.codigo)).join('\r* '))
                        return
                    }
                    tableSelection.forEach(e => { e.activo = false })
                    setIsDelReacOpened(false)
                    setTableSelection([])
                })
                break
            default: return
        }
    }

    const formatDataForTable = (datos) => {
        datos.forEach(e => {
            e.nombre = e.id > 0 ? e.nombre : Traduccion(e.nombre)
            e.tipoOperacion = e.signo > 0 ? Traduccion('TXT_INGRESO') : Traduccion('TXT_EGRESO')
            e.formasDeCobro.forEach(f =>
                f.descripcion = f.id < 0
                    ? Traduccion(f.descripcion)
                    : f.descripcion)
            e.formasDeCobro.sort((e1, e2) => e1.descripcion > e2.descripcion ? 1 : -1)
            //en casos puntuales indico que muestre "Todas" como forma de pago
            e.formasDeCobroTxt = [-3, -2, 1].includes(e.id)
                ? Traduccion('TXT_TODAS')
                : e.formasDeCobro.map(formaCobro => formaCobro.descripcion).join(', ')
        })
        datos.sort((e1, e2) => e1.nombre > e2.nombre ? 1 : -1)
    }

    React.useEffect(() => {
        setIsTableLoading(true)
        listarMotivosMovimiento({ incluirFormasCobro: true })
            .then(data => {
                formatDataForTable(data) //a futuro usar data.objetoOperado
                setTableData(data)
                setIsTableLoading(false)
            })
    }, [])

    return <div>
        <DataTable title='TXT_MOTIVOS_MOVIMIENTO'
            headers={['TXT_NOMBRE', 'TXT_TIPO_MOVIMIENTO', 'TXT_FORMAS_DE_COBRO']}
            types={[Tipos().DESC, Tipos().DESC, Tipos().DESC]}
            access={['nombre', 'tipoOperacion', 'formasDeCobroTxt']}
            data={tableData}
            isLoading={isTableLoading}
            rowId={'id'}
            openDetail={handleDetailOpen}
            hasAdd hasEdit hasReac hasDel />
        <Detalle accion={detailAction}
            isOpen={isDetailOpened}
            onSubmit={handleDetailSubmit}
            seleccion={tableSelection[0]} />
        <ModalDelete isOpen={isDelReacOpened}
            seleccion={tableSelection.map(e => e.nombre)}
            action={detailAction}
            onSubmit={handleDetailSubmit} />
    </div>
}