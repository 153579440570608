import React from 'react'
import {Checkbox,
        Table,
        TableBody,
        TableCell,
        TableContainer,
        TableHead,
        TableRow,
        Toolbar,
        Tooltip,
        Typography,
        Paper,
        alpha
        }  from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import UndoIcon from '@mui/icons-material/Undo'
import FilterListIcon from '@mui/icons-material/FilterList'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { PropTypes } from 'prop-types'

import { EmptyRow, LoadingRows } from './DataTableComp'
import { TooltipButton } from './TooltipButton'
import { Traduccion } from './Traduccion'

import { Acciones } from '../utils/Acciones'
import { FormatFechaHora, FormatImporte, FormatCantidad } from '../utils/Formatter'
import { Tipos } from '../utils/Tipos'

const SelectAllCheckBox = ({numSelected, rowCount, onClick}) => {
    return <Checkbox indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onClick}
              inputProps={{'aria-label': 'select all'}}
              />;
}
    
export const DataTable = ({title, headers, types, access, data = [], openSearch, openDetail, hasAdd = false, 
    hasEdit = false, hasDel = false, hasReac = false, hasSee = true, isLoading = false, showStatusTootip = true, 
    component = Paper, rowId}) => {

    const [selected, setSelected] = React.useState([]);
    //config
    const showStatusCol = data.length > 0 && 'activo' in data[0] && showStatusTootip;
    const hayActivosSeleccionados = data.filter(e => selected.includes(e.id) && e.activo === true).length > 0;
    const hayInactivosSeleccionados = data.filter(e => selected.includes(e.id) && e.activo === false).length > 0;
    //options
    const showCheckBoxes = hasEdit || hasDel || hasSee;
    const hasSelected = selected.length > 0;
    const showBtnAdd = hasAdd && !hasSelected;
    const showBtnEdit = hasEdit && selected.length === 1;
    const showBtnSee = !hasEdit && hasSee && selected.length === 1;
    const showBtnDel = hasDel && hasSelected && (!showStatusCol || (showStatusCol && hayActivosSeleccionados));
    const showBtnReac = hasReac && hasSelected && (!showStatusCol || (showStatusCol && hayInactivosSeleccionados));
    const showBtnSearch = openSearch && !hasSelected;
    //config
    const colspan = () => {
        let span = headers.length;
        if (showCheckBoxes) {
            span += 1;
        }
        if (showStatusCol) {
            span += 1;
        }
        return span;
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = data.filter(row => row.editable || row.editable === undefined).map(row => row[rowId]);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };
    
    const handleRowClick = (event, obj) => {
        let id = obj[rowId]
        if (showCheckBoxes && (obj.editable || obj.editable === undefined)) {
            const selectedIndex = selected.indexOf(id);
            let newSelected = [];
    
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selected, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
                newSelected = newSelected.concat(selected.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                        selected.slice(0, selectedIndex),
                        selected.slice(selectedIndex + 1),
                        );
            }
            setSelected(newSelected)
        }
    }

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const getAlignFrom = (type) => type === Tipos().DESC ? 'left' : 'right';
    
    const getValFromRuta = (obj, ruta) => {
        if (ruta.length===1) {
            return obj[ruta];
        } else {
            return getValFromRuta(obj[ruta.shift()], ruta);
        }
    };
    
    const getValFormateado = (valor, tipoDato) => {
        switch (tipoDato) {
            case Tipos().FECHA: return FormatFechaHora(valor);
            case Tipos().IMP: return FormatImporte(valor);
            case Tipos().CANT: return FormatCantidad(valor);
            default: return valor;
        }
    };
    
    const getCellVal = (obj, ruta, tipoDato) => {
        return getValFormateado(getValFromRuta(obj, ruta.split('.')), tipoDato);
    };
    
    const _handleOpenAdd = () => _handleOpenDetail(Acciones().ADD, undefined);
    const _handleOpenEdit = () => _handleOpenDetail(Acciones().EDIT, selected[0]);
    const _handleOpenSee = () => _handleOpenDetail(Acciones().SEE, selected[0]);
    const _handleOpenDel = () => _handleOpenDetail(Acciones().DEL, selected);
    const _handleOpenReac = () => _handleOpenDetail(Acciones().REAC, selected);
    
    const _handleOpenDetail = (action, value) => {
        setSelected([]);
        openDetail(action, value);
    };

    return (
            <TableContainer component={component} >
                <Toolbar sx={{
                            ...(hasSelected > 0 && {
                            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                            }),
                        }}>
                    <Typography sx={{flex: '1 1 100%'}}>
                        {hasSelected ? Traduccion('TXT_SELECCION') + ': ' + selected.length : Traduccion(title)}
                    </Typography>
                    { showBtnAdd &&
                            <TooltipButton label="TXT_AGREGAR" 
                                           onClick={_handleOpenAdd}>
                                <AddIcon/>
                            </TooltipButton>
                    }
                    { showBtnEdit &&
                            <TooltipButton label="TXT_EDITAR" 
                                           onClick={_handleOpenEdit}>
                                <EditIcon/>
                            </TooltipButton>
                    }
                    { showBtnSee &&
                            <TooltipButton label="TXT_VER" 
                                           onClick={_handleOpenSee}>
                                <VisibilityIcon/>
                            </TooltipButton>
                    }
                    { showBtnDel &&
                            <TooltipButton label="TXT_BORRAR" 
                                           onClick={_handleOpenDel}>
                                <DeleteIcon/>
                            </TooltipButton>
                    }
                    { showBtnReac &&
                            <TooltipButton label="TXT_REACTIVAR" 
                                           onClick={_handleOpenReac}>
                                <UndoIcon/>
                            </TooltipButton>
                    }
                    { showBtnSearch &&
                            <TooltipButton label="TXT_FILTRAR" 
                                           onClick={openSearch}>
                                <FilterListIcon/>
                            </TooltipButton>

                    }
                </Toolbar>
                <Table aria-label={Traduccion(title)}>
                    <TableHead>
                        <TableRow>
                            { showCheckBoxes &&
                            <TableCell padding="checkbox">
                                <SelectAllCheckBox 
                                    numSelected={selected.length} 
                                    rowCount={data.filter(row => row.editable || row.editable === undefined).length} 
                                    onClick={handleSelectAllClick}/>
                            </TableCell>
                            }
                            { showStatusCol && 
                                <TableCell padding="checkbox"/>
                            }
                            {headers.map((header, idx) => (
                                <TableCell key={idx} align={getAlignFrom(types[idx])}><b>{Traduccion(header)}</b></TableCell>
                                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { isLoading && <LoadingRows span={colspan()}/> }
                        { !isLoading && data.length === 0 && <EmptyRow span={colspan()}/> }
                        { !isLoading && data.length > 0 &&
                                    data.map((obj, idx) => {

                                        //const isItemSelected = isSelected(obj[rowId]);
                                        //const labelId = `checkbox-${idx}`;

                                        return <TableRow key={idx} 
                                                  role="checkbox"
                                                  onClick={e => handleRowClick(e, obj)}
                                                  aria-checked={isSelected(obj[rowId])}
                                                  selected={isSelected(obj[rowId])}> 
                                            { showCheckBoxes &&
                                                                        <TableCell padding="checkbox">
                                                                            <Checkbox checked={isSelected(obj[rowId])}
                                                                                      inputProps={{'aria-labelledby': `checkbox-${idx}`}}
                                                                                      disabled={obj.editable || obj.editable === undefined ? false : true}/>
                                                                        </TableCell>
                        }
                                            { showStatusCol && 
                                                    <TableCell padding="checkbox">
                                                        { !obj.activo &&
                                                                <Tooltip title={Traduccion('MSJ_DADO_DE_BAJA')}>
                                                                    <DeleteIcon/>
                                                                </Tooltip>
                                                        }
                                                    </TableCell>
                                            }
                                            {access.map((ruta, index) => (
                                                                            <TableCell key={index} align={getAlignFrom(types[index])}>{getCellVal(obj,ruta,types[index])}</TableCell>
                                                                                            ))}
                                        </TableRow>;
                                    })}
                    </TableBody>
                </Table>
            </TableContainer>
            );
}

DataTable.propTypes = {
    title: PropTypes.string.isRequired,
    headers: PropTypes.arrayOf(PropTypes.string).isRequired,
    types: PropTypes.arrayOf(PropTypes.string).isRequired,
    data: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired
};