import React from 'react'
import { DataTable } from '../../comp/DataTableV2'
import { Detalle } from './Detalle'
import { Filtros } from './Filtros'
import { Messages } from '../../comp/Messages2'
import { ModalDelete } from '../../comp/ModalDelete'
import { borrarProductos, listarProductos, reactivarProductos } from '../../services/servProductos'
import { Acciones } from '../../utils/Acciones'
import { Tipos } from '../../utils/Tipos'
import { Acceso, NivelAcceso } from '../../utils/Seguridad'

const vista = 'ViewProductos';

export const Productos = () => {

    const [tData, setTData] = React.useState([])
    const [filters, setFilters] = React.useState({ codigo: null, nombre: '', categoriaId: null, activo: true})
    const [isSearchOpened, setIsSearchOpened] = React.useState(false)
    const [isDetailOpened, setIsDetailOpened] = React.useState(false)
    const [isDelReacOpened, setIsDelReacOpened] = React.useState(false)
    const [isTableLoading, setIsTableLoading] = React.useState(true)
    const [seleccion, setSeleccion] = React.useState([])
    const [modalAction, setModalAction] = React.useState(Acciones().SEE)
    const [resServicio, setResServicio] = React.useState()

    const puedeVerPrecioCompra = Acceso('ViewPedidosProveedores', NivelAcceso().VER)
        || Acceso(vista, NivelAcceso().AGREGAR)
    const puedeVerPrecioVenta = Acceso('ViewVentasPorCaja', NivelAcceso().AGREGAR)

    const _handleOpenDetail = (action, selection) => {
        setModalAction(action)
        setResServicio()
        setSeleccion(selection ? tData.filter(e => (selection.length && selection.indexOf(e.id) > -1) || e.id === selection) : [])
        if ([Acciones().DEL, Acciones().REAC].includes(action)) {
            setIsDelReacOpened(true)
        } else {
            setIsDetailOpened(true)
        }
    }

    const handleSearchSubmit = (submit, filtros) => {
        setIsSearchOpened(false)
        if (submit) {
            setFilters(filtros)
        }
    }

    const handleDetailSubmit = (obj) => {
        setIsDetailOpened(false)
        setSeleccion([])
        //si viene un objeto
        if (obj) {
            if (modalAction === Acciones().ADD) { //si es alta: lo dejo solo en la tabla
                setTData([obj])
            } else { //si edición: reemplazo el item seleccionado en la tabla
                setTData(tData.map(e => {return e.id === obj.id ? obj : e}))
            }
        }
    }

    const handleDelReacSubmit = async (submit) => {
        if (!submit) return setIsDelReacOpened(false)
        let resOp = (modalAction === Acciones().DEL)
                        ? await borrarProductos(seleccion.map(e => e.id))
                        : await reactivarProductos(seleccion.map(e => e.id))
        setResServicio(resOp)
        if (resOp.status === 200) {
            setTData(tData.map(e =>
                    seleccion.some(sel => sel.id === e.id)
                        ? { ...e, activo: modalAction === Acciones().REAC }
                        : e
            ))
            setIsDelReacOpened(false)
            setSeleccion([])
        }
    }
    
    const getHeaders = () => {
        let headers = ['TXT_CODIGO', 'TXT_NOMBRE', 'TXT_STOCK'];
        puedeVerPrecioCompra && headers.push('TXT_COSTO_MIN');
        puedeVerPrecioVenta && headers.push('TXT_VENTA_MIN');
        return headers;
    }
    
    const getTypes = () => {
        let types = [Tipos().COD, Tipos().DESC, Tipos().CANT];
        puedeVerPrecioCompra && types.push(Tipos().IMP);
        puedeVerPrecioVenta && types.push(Tipos().IMP);
        return types;
    }
    
    const getAccess = () => {
        let access = ['codigo', 'nombre', 'cantidad'];
        puedeVerPrecioCompra && access.push('precioCosto');
        puedeVerPrecioVenta && access.push('precioVenta');
        return access;
    }

    const search = (filtros) => {
        setIsTableLoading(true)
        setResServicio()
        listarProductos(filtros).then(resListar => {
            setTData(resListar.data)
            resListar.errores && setResServicio(resListar)
            setIsTableLoading(false)
        })
    }
    React.useEffect(() => {
        search(filters)
    }, [filters])

    return <>
        <DataTable title={vista}
            headers={getHeaders()}
            types={getTypes()}
            access={getAccess()}
            rowId="id"
            data={tData}
            openSearch={() => setIsSearchOpened(true)}
            openDetail={_handleOpenDetail}
            isLoading={isTableLoading}
            hasAdd={Acceso(vista, NivelAcceso().AGREGAR)}
            hasEdit={Acceso(vista, NivelAcceso().EDITAR)}
            hasDel={Acceso(vista, NivelAcceso().BORRAR_REINSERTAR)}
            hasReac={Acceso(vista, NivelAcceso().BORRAR_REINSERTAR)} />
        <Filtros isOpen={isSearchOpened}
            onSubmit={handleSearchSubmit}
            filtros={filters} />
        <Detalle isOpen={isDetailOpened}
            onSubmit={handleDetailSubmit}
            seleccion={seleccion[0]}
            accion={modalAction}
            verPrecioCompra={puedeVerPrecioCompra}
            verPrecioVenta={puedeVerPrecioVenta} />
        <ModalDelete isOpen={isDelReacOpened}
            seleccion={seleccion.map(e => e.nombre)}
            action={modalAction}
            onSubmit={handleDelReacSubmit} />
        <Messages resServicio={resServicio} accion={modalAction}/>
    </>
    
}