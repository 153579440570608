export const Tipos = () => {
    return {
        COD: 'NUM',
        DESC: 'STR',
        CANT: 'CANT',
        IMP: 'IMP',
        FECHA: 'DATE',
        NUM: 'NUM',
        PJE: 'PJE'
    };
};