import { GetCall } from './_serviceHelperMsa'

const serviceUrl = '/mesas'

export const listarMesas = (filters) => {
    let params;
    if (filters) {
        params = [
            { 'key': 'id', 'val': filters.id }
        ];
    }
    return GetCall(serviceUrl, params)
}