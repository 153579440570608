import { getEncodedToken } from '../config/Sesion'

const HeaderConfig = (method) => {
    return {
        method: method,
        headers: {
            'X-Bearer': getEncodedToken()
        }
    };
};

const HeaderConfig2 = (method, bodyObject) => {
    return {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'X-Bearer': getEncodedToken()
        },
        body: JSON.stringify(bodyObject)
    };
};

const GetQueryParams = (filters) => {
    if (filters === undefined) {
        return '';
    }
    var filledParams = filters.filter(obj => obj.val !== null && obj.val !== undefined && obj.val !== '');
    if (filledParams.length > 0) {
        return '?' + filledParams.map(obj => obj.key + '=' + obj.val).join('&');
    }
    return '';
};

const fetchCall = (url, config, setStatus) => {
    return fetch(url, config)
            .then(response => {
                if (setStatus) {
                    setStatus(response.status);
                }
                switch (response.status) {
                    case 401:
                        alert('Usuario deslogueado, vuelva a ingresar');
                        return {};
                    case 403:
                        alert('Usuario no tiene acceso');
                        return {};
                    case 500:
                        alert('No se pudo procesar lo solicitado');
                        console.error(response);
                        return {};
                    default:
                        break;
                }
                return response;
            })
            .catch(err => {
                alert('Problema de conexión:\r\nrevisar conectividad a internet');
                console.error(err);
                if (setStatus) {
                    setStatus(500);
                }
                return {};
            });
};

export const GetCall = (urlBase, url, params, setStatus) => {
    return fetchCall(urlBase + url + GetQueryParams(params), HeaderConfig('GET'), setStatus)
            .then(response => {
                try {
                    return response.json();
                } catch (error) {
                    return [];
                }
            });
};

export const GetCallPdfResp = (urlBase, url, params, setStatus) => {
    return fetchCall(urlBase + url + GetQueryParams(params), HeaderConfig('GET'), setStatus)
            .then(response => {
                try {
                    return response.blob();
                } catch (error) {
                    return response;
                }
            });
};

export const PostCall = (urlBase, url, body, setStatus) => {
    return fetchCall(urlBase + url, HeaderConfig2('POST', body), setStatus)
            .then(response => {
                try {
                    return response.json();
                } catch (error) {
                    return {};
                }
            });
};

/** 
 * @deprecated la respuesta no es mapeada si recibe un 200, usar PutCallV2
 */
export const PutCall = (urlBase, url, body, setStatus) => {
    return fetchCall(urlBase + url, HeaderConfig2('PUT', body), setStatus)
            .then(response => {
                try {
                    return response.status !== 200 ? response.json() : response;
                } catch (error) {
                    return {};
                }
            });
};

export const PutCallV2 = (urlBase, url, body, setStatus) => {
    return fetchCall(urlBase + url, HeaderConfig2('PUT', body), setStatus)
            .then(response => {
                try {
                    return response.json();
                } catch (error) {
                    return {};
                }
            });
};

export const DeleteCall = (urlBase, url, pathParam, setStatus) => {
    return fetchCall(urlBase+url+'/'+pathParam, HeaderConfig('DELETE'), setStatus)
    .then(response => {
        try {
            return response.json();
        } catch (error) {
            return [];
        }
    })
}