import { GetCall, GetCallPdfResp, PostCall, PutCall } from './_serviceHelperCor'
import { dateToJson } from '../utils/DateUtil';

const serviceUrl = '/cajas';

export const listar = (filters, responseStatus) => {
    var params = [
        {'key': 'id', 'val': filters.id},
        {'key': 'cajeroId', 'val': filters.user.id},
        {'key': 'fechaDesde', 'val': dateToJson(filters.desde)},
        {'key': 'fechaHasta', 'val': dateToJson(filters.hasta)},
        {'key': 'activo', 'val': filters.activo},
    ]
    return GetCall(serviceUrl, params, responseStatus).then(response => {return {datos: response}})
};

export const abrir = (caja, responseStatus) => {
    return PostCall(serviceUrl, caja, responseStatus);
};

export const cerrar = (caja, responseStatus) => {
    return PutCall(serviceUrl+'/cerrar', {id:caja.id}, responseStatus);
};

export const reabrir = (caja, responseStatus) => {
    return PutCall(serviceUrl, {id:caja.id}, responseStatus);
};

export const pdfCierre = (caja, responseStatus) => {
    var params = [
        {'key': 'id', 'val': caja.id}
    ];
    return GetCallPdfResp(serviceUrl+'/pdf/cierre', params, responseStatus);
};

export const listarModificaciones = (idCaja, responseStatus) => {
    var params = [
            {'key': 'id', 'val': idCaja}
    ];
    return GetCall(serviceUrl+'/modificaciones', params, responseStatus);
};