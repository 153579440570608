import * as React from 'react'
import PropTypes from 'prop-types'
import { BarChart } from '@mui/x-charts/BarChart'
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge'
import { LineChart } from '@mui/x-charts/LineChart'
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart'

import { axisClasses } from '@mui/x-charts/ChartsAxis'
import { LoadingRect, LoadingCirc } from './LoadingComp'
import { Grid } from '@mui/material'

const defaultChartSettings = {
  height: 200,
  width: 400,
  legend: { hidden: true },
  margin: { top: 5, right: 20, bottom: 30, left: 100 },
  /*sx: {
    [`.${axisClasses.left} .${axisClasses.label}`]: {
      transform: 'translate(-35px, 0)',
    },
  },*/
}

export const GraficoBarras = ({dataset, isLoading = false, labelKey, labelFormatter, labelAxisDesc, dataKeysAndLabels, dataFormatter, horizontal, stack}) => {

  const [chartSettings, setChartSettings] = React.useState({...defaultChartSettings})
  
  React.useEffect(() => {
    let auxSettings = {...defaultChartSettings}
    const valuesAxis = [{label: labelAxisDesc}]
    const labelsAxis = [{
      scaleType: 'band',
      dataKey: labelKey,
      valueFormatter: (value) => labelFormatter ? labelFormatter(value) : value
    }]
    if (horizontal) {
      auxSettings.layout = 'horizontal'
      auxSettings.xAxis = valuesAxis
      auxSettings.yAxis = labelsAxis
    } else {
      auxSettings.xAxis = labelsAxis
      auxSettings.yAxis = valuesAxis
    }
    setChartSettings(auxSettings)
  },[labelKey, labelFormatter, labelAxisDesc, horizontal])

  return <>
    {isLoading &&
      <LoadingRect rows={4} height={chartSettings.height/6.65} />
    }
    {!isLoading &&
    <BarChart
      dataset={dataset}
      series={Object.keys(dataKeysAndLabels).map(key => (
        {
          dataKey: key, //de qué atributo sacar el dato
          label: dataKeysAndLabels[key], //qué nombre mostrar sobre este atributo
          stack,
          valueFormatter: (value) => dataFormatter ? dataFormatter(value) : value //como formatear el valor del atributo
        }
      ))}
      options={{
        maintainAspectRatio: false,
      }}
      {...chartSettings}
    />
  }
  </>
}

GraficoBarras.propTypes = {
  dataset: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  labelKey: PropTypes.string.isRequired,
  labelFormatter: PropTypes.func,
  labelAxisDesc: PropTypes.string,
  dataKeysAndLabels: PropTypes.object.isRequired,
  dataFormatter: PropTypes.func, 
  horizontal: PropTypes.bool
}

export const GraficoLineas = ({dataset, isLoading = false, labelKey, labelFormatter, dataAxisDesc, dataKeysAndLabels, dataFormatter}) => {
  
  const [chartSettings, setChartSettings] = React.useState({...defaultChartSettings})
  
  React.useEffect(() => {
    let auxSettings = {...defaultChartSettings}
    //auxSettings.margin.bottom = 30
    auxSettings.xAxis = [{
      scaleType: 'point',
      dataKey: labelKey,
      valueFormatter: (value) => labelFormatter ? labelFormatter(value) : value,
      min: 1,
    }]
    auxSettings.yAxis = [{label: dataAxisDesc}]
    auxSettings.sx = {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        transform: 'translate(-35px)',
      },
    }
    setChartSettings(auxSettings)
  },[labelKey, labelFormatter, dataAxisDesc])

  return <>
  {isLoading &&
    <LoadingRect rows={1} height={chartSettings.height/1.2} />
  }
  {!isLoading &&
    <LineChart
      dataset={dataset}
      series={Object.keys(dataKeysAndLabels).map((key) => ({
          dataKey: key, //de qué atributo sacar el dato
          label: dataKeysAndLabels[key], //qué nombre mostrar sobre este atributo
          showMark: false, //que no muestre los puntos sobre las líneas graficadas
          valueFormatter: (value) => dataFormatter ? dataFormatter(value) : value //como formatear el valor del atributo
      }))}
      bottomAxis={{
        tickLabelStyle: {
          angle: -45,
          textAnchor: 'end',
          fontSize: 10
        }
      }}
      {...chartSettings}
    />
  }
</>
}

GraficoLineas.propTypes = {
  dataset: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  labelKey: PropTypes.string.isRequired,
  labelFormatter: PropTypes.func,
  labelAxisDesc: PropTypes.string,
  dataKeysAndLabels: PropTypes.object.isRequired,
  dataFormatter: PropTypes.func
}

export const GraficoTorta = ({dataset, isLoading = false, labelKey, labelFormatter, dataAxisDesc, dataKeysAndLabels, dataFormatter}) => {
  
  const [chartSettings, setChartSettings] = React.useState({...defaultChartSettings})

  const radiusMinOuter = 0
  const radiusMaxOuter = chartSettings.height / 2
  const radiusStep = (radiusMaxOuter - radiusMinOuter) / dataset.length

  const total = () => dataset[0].map(n2 => n2[dataKeysAndLabels[0]]).reduce((acc, el) => acc + el, 0)
  const arcLabelFormat = (unValor) => unValor*100/total() < 1 
    ? `< 1 %`
    : `${(unValor*100/total()).toFixed(0)} %`
  
  React.useEffect(() => {
    let auxSettings = {...defaultChartSettings}
    auxSettings.legend = {
      hidden: false
    }/*
    auxSettings.slotProps = {
      legend : {
        position: { vertical: 'middle', horizontal: 'right' },
        padding: 1000, 
      }
    }*/
    auxSettings.margin = undefined //{ top: 0, left: 0, bottom: 0 }
    auxSettings.sx = {
      [`& .${pieArcLabelClasses.root}`]: {
        fill: 'black',
        textShadow: '0px 0px 5px rgba(255, 255, 255, 1)',
      },
    }
    setChartSettings(auxSettings)
  },[labelKey, labelFormatter, dataAxisDesc])

  return <>
    {isLoading &&
      <>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <LoadingCirc dimension='10.5em'/>
        </Grid>
        <Grid item xs={4}>
          <LoadingRect rows={4} height={chartSettings.height/6.65} />
        </Grid>
      </Grid>
      </>
    }
    {!isLoading &&
      <PieChart
          slotProps={{
            legend: {
              direction: 'column',
              position: { vertical: 'middle', horizontal: 'right' },
              padding: 0,
              itemMarkWidth: 20,
              itemMarkHeight: 5,
            },
          }}
          series={dataset.map((nivel1,idx) => ({
            innerRadius: idx * radiusStep,
            outerRadius: (idx + 1) * radiusStep,
            data: nivel1.map(nivel2 => ({
              id: nivel2[labelKey],
              label: labelFormatter ? labelFormatter(nivel2[labelKey], idx) : nivel2[labelKey],
              value: nivel2[dataKeysAndLabels[idx]]
            })),
            valueFormatter: (item) => dataFormatter ? dataFormatter(item.value) : item.value,
            arcLabel: (item) => arcLabelFormat(item.value),
            arcLabelMinAngle: 0,
            arcLabelRadius: (80 / dataset.length) * (idx + 1)
          }))}
          {...chartSettings}
        />
    }
    </>
}

GraficoTorta.propTypes = {
  dataset: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  labelKey: PropTypes.string.isRequired,
  labelFormatter: PropTypes.func,
  labelAxisDesc: PropTypes.string,
  dataKeysAndLabels: PropTypes.object.isRequired,
  dataFormatter: PropTypes.func
}

export const GraficoGauge = ({value, isLoading = false, valueMin = 0, valueMax = 100, labelFormatter}) => {
  
  const [chartSettings, setChartSettings] = React.useState({...defaultChartSettings})

  const radius = 100; // Radio del gauge
  const centerX = 220; // Centro X del gauge
  const centerY = 200; // Centro Y del gauge

  // Función para calcular la posición de los textos
  const calculatePosition = (angle) => {
    const radian = (angle * Math.PI) / 180;
    return {
      x: centerX + radius * Math.cos(radian),
      y: centerY + radius * Math.sin(radian)
    }
  }

  const startPos = calculatePosition(180)
  const endPos = calculatePosition(0)

  const getFontSize = () => {
    const valueLength = labelFormatter ? labelFormatter(value).length : value.toString().length
    if (valueLength <= 10) return 40
    if (valueLength > 10 && valueLength <= 14) return 30
    return 20
  }

  React.useEffect(() => {
    let auxSettings = {...defaultChartSettings}
    auxSettings.margin = undefined //{ top: 0, left: 0, bottom: 0 }
    if (labelFormatter) {
      auxSettings.text = labelFormatter(value)
    }
    setChartSettings(auxSettings)
  },[value, labelFormatter, valueMax])

  return <>
    {isLoading &&
      <LoadingCirc dimension='10.5em'/>
    }
    {!isLoading && 
      <Gauge value={value}
          valueMin={valueMin}
          valueMax={valueMax}
          startAngle={-110}
          endAngle={110}
          sx={{
            [`& .${gaugeClasses.valueText}`]: { fontSize: getFontSize() }
          }}
          {...chartSettings}
      >
        <svg style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
          <text x={startPos.x} y={startPos.y} textAnchor="end" style={{ fontSize: 12, fill: 'black' }}>{
            labelFormatter ? labelFormatter(valueMin) : valueMin}</text>
          <text x={endPos.x} y={endPos.y} textAnchor="end" style={{ fontSize: 12, fill: 'black' }}>{
            labelFormatter ? labelFormatter(valueMax) : valueMax}</text>
        </svg>
      </Gauge>
    }
    </>
}

GraficoGauge.propTypes = {
  value: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
  labelFormatter: PropTypes.func,
  valueMin: PropTypes.number,
  valueMax: PropTypes.number
}