import { GetCall, PostCall } from './_serviceHelperSeg'

const serviceUrl = '/tenant/modulos/afip'

export const getCSRAfip = () => {
    return GetCall(serviceUrl)
}

export const enableAfip = (obj, responseStatus) => {
    return PostCall(serviceUrl, obj, responseStatus).then(d => { return { errores: d.errores, entidad: d.data } })
}