import React from 'react'
import { Typography }  from '@mui/material'

export const Titulo = ({children, ...rest}) => {
    return <Typography color="inherit" variant="h5" style={{flex: 1}} {...rest}>
        { children }
    </Typography>;
};

export const Normal = ({children, ...rest}) => {
    return <Typography color="inherit" variant="body2" {...rest}>
        { children }
    </Typography>;
};