import React from 'react'
import { Button,
        Card,
        CardActions,
        CardContent,
        CardMedia,
        LinearProgress,
} from '@mui/material'
import TextField from '@mui/material/TextField'
import Skeleton from '@mui/material/Skeleton'
import MobileStepper from '@mui/material/MobileStepper'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import Avatar from '@mui/material/Avatar'
import Chip from '@mui/material/Chip'
import Box from '@mui/material/Box'
import { Traduccion } from '../Traduccion'
import { inicializarSesion } from '../../config/Sesion'
import { getTenantInfo, saveTenantInfo, getLastTenantUser } from '../../config/Storage'
import { listarLogos, listarTenantsDisponibles, login } from '../../services/servUsuarios'

export const LoginCard = ({onErrors, onSuccess}) => {

    const [user, setUser] = React.useState()
    
    const _handleUserSubmit = (res) => {
        if (res.errores) {
            onErrors(res.errores[0])
        } else {
            res.user.lastTenantId = getLastTenantUser(res.user)
            setUser(res.user)
        }
    }
    
    const _handlePassSubmit = (res) => {
        if (res.return) {
            setUser(null)
        } else if (res.errores) {
            onErrors(res.errores[0])
        } else {
            inicializarSesion(user, res.tenantId, res.token)
            onSuccess()
        }
    }

    return <Card style={{maxWidth: 300, margin: '0 auto'}}>
           { !user
                ? <UserCardContent onSubmit={_handleUserSubmit}/>
                : <PassCardContent onSubmit={_handlePassSubmit} userData={user}/>
           }
           </Card>
}

const UserCardContent = ({onSubmit}) => {
    
    const [email, setEmail] = React.useState('')
    const [isLoading, setIsLoading] = React.useState(false)
    
    
    const _handleSubmit = (e) => {
        e.preventDefault()
        setIsLoading(true)
        listarTenantsDisponibles(email).then(d => {
            setIsLoading(false)
            onSubmit({
                errores: d.errores, 
                user: {
                    email: email, 
                    tenants: d.data
                }
            })
        })
    }
    
    return <div>
            <CardMediaCustom defaultImage/>
            {isLoading &&
                <LinearProgress />}
            <form onSubmit={_handleSubmit}>
                <CardContent>
                    <TextField
                        name="username"
                        label={Traduccion('TXT_USUARIO')}
                        variant="outlined"
                        margin="dense"
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                        disabled={isLoading}
                        autoComplete="username"
                        fullWidth
                        autoFocus
                        />
                </CardContent>
                <CardActions sx={{justifyContent: 'center'}}>
                    <Button variant="contained" color="primary" type="submit" disabled={isLoading}>
                        {Traduccion('TXT_SIGUIENTE')}
                    </Button>
                </CardActions>
                <br/>
            </form>
        </div>
}

const PassCardContent = ({onSubmit, userData}) => {
    
    const [pass, setPass] = React.useState('')
    const [isLoading, setIsLoading] = React.useState(false)
    const [selectedTenantIdx, setSelectedTenantIdx] = React.useState(0)
    const [tenantList, setTenantList] = React.useState([])
    
    React.useEffect(()=>{
        setSelectedTenantIdx(getTenantIndexById(userData.tenants, userData.lastTenantId))
        //setTenantList(userData.tenants)
        let logosToFind = []
        let listaAuxTenants = []
        userData.tenants.forEach(t => { //busco los logos que tengo guardados para mostrar
            let infoGuardadaTenant = getTenantInfo(t.id)
            if (!infoGuardadaTenant || infoGuardadaTenant.versionLogo < t.versionLogo) {
                logosToFind.push(t.id) //si no tengo tenant o el logo obsoleto lo busco
                listaAuxTenants.push(t)
            } else {
                listaAuxTenants.push(infoGuardadaTenant)
            }
        })
        setTenantList(listaAuxTenants)
        if (logosToFind.length > 0) { //si no tengo logos o si están obsoletos los busco de nuevo
            listarLogos(userData.email, logosToFind).then(res => {
                res.data.forEach(t => {
                    let infoGuardadaTenant = getTenantInfo(t.id)
                    if (infoGuardadaTenant) {
                        infoGuardadaTenant.logo = t.logo
                        infoGuardadaTenant.versionLogo = t.versionLogo
                    } else {
                        infoGuardadaTenant = t
                    }
                    saveTenantInfo(infoGuardadaTenant)
                    listaAuxTenants.find(t2 => t2.id === t.id).logo = t.logo
                })
                setTenantList(listaAuxTenants)
            })
        }
    },[userData])
    
    const getTenantIndexById = (tenants, tenantId) => {
        if (!tenantId) return 0
        let idx = tenants.indexOf(tenants.find(e => e.id === tenantId))
        return idx < 0 ? 0 : idx
    }
    
    const _handleBack = () => onSubmit({return: true})
    const _handlePassChange = (e) => setPass(e.target.value)
    const _handleNextTenant = () => setSelectedTenantIdx(selectedTenantIdx + 1)
    const _handlePrevTenant = () => setSelectedTenantIdx(selectedTenantIdx - 1)
    
    const _handleSubmit = (e) => {
        e.preventDefault()
        setIsLoading(true)
        login(userData.email, pass, tenantList[selectedTenantIdx].id).then(d => {
            setIsLoading(false)
            onSubmit({tenantId: tenantList[selectedTenantIdx].id, token: d.data, errores: d.errores})
        })
    }
    
    const maxSteps = tenantList.length-1
    
    return <div>
            { tenantList.length === 1
            ? <CardMediaCustom base64Img={tenantList[0].logo}/>
            : <div>
                <MobileStepper
                    variant="dots"
                    steps={tenantList.length}
                    position="static"
                    activeStep={selectedTenantIdx}
                    nextButton={
                        <IconButton aria-label="next" onClick={_handleNextTenant} disabled={isLoading || selectedTenantIdx===maxSteps}>
                          <KeyboardArrowRight />
                        </IconButton>
                        }
                    backButton={
                        <IconButton aria-label="previous" onClick={_handlePrevTenant} disabled={isLoading || selectedTenantIdx===0}>
                            <KeyboardArrowLeft />
                        </IconButton>
                    }
                />
                {tenantList.map((t, idx) => 
                    selectedTenantIdx === idx && (
                            <CardMediaCustom key={t.id} base64Img={t.logo}/>
                    )
                )}
            </div>
            }
            {isLoading &&
                <LinearProgress />}
            <form onSubmit={_handleSubmit}>
                <CardContent>
                    <Box sx={{textAlign: 'center'}}>
                        <Chip avatar={<Avatar>{userData.email.substring(0,1).toUpperCase()}</Avatar>}
                            label={userData.email}
                            color="primary"
                            variant="outlined"
                            onDelete={_handleBack}
                            disabled={isLoading}/>
                    </Box><br/>
                    <input type="text" name="username" value={userData.email}
                        autoComplete="username" style={{display:'none'}} readOnly
                        />
                    <TextField
                        type="password"
                        label={Traduccion('TXT_PASS')}
                        variant="outlined"
                        margin="dense"
                        onChange={_handlePassChange}
                        disabled={isLoading}
                        autoComplete="current-password"
                        fullWidth
                        autoFocus
                        />
                </CardContent>
                <CardActions sx={{justifyContent: 'center'}}>
                    <Button variant="contained" color="primary" type="submit" disabled={isLoading}>
                        {Traduccion('TXT_INICIAR_SESION')}
                    </Button>
                </CardActions>
                <br/>
            </form>
        </div>
}

const CardMediaCustom = ({base64Img, defaultImage}) => {
    const styles = {
        logo: {
            height: '12.5em',
            width: '100%',
            objectFit: 'cover'
        }
    }
    return base64Img 
           ? <CardMedia image={'data:null;base64,' + base64Img} sx={styles.logo}/>
           : defaultImage
                ? <CardMedia image={'logoMain.png'} sx={styles.logo}/>
                : <Skeleton variant="rect" animation="wave" sx={styles.logo}/>
}