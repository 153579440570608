import React, {Component} from 'react'
import properties from '../../package.json'
import { Navigate } from 'react-router-dom';
import { LoginCard } from '../comp/login/LoginCard'
import { Messages } from '../comp/Messages'

export class Login extends Component {

    state = {
        errores: '',
        redirect: false,
        tenantId: 1,
        logo: null
    }

    _handleErrors = (errores) => {
        this.setState({errores});
    }

    _handleSuccess = () => {
        this.setState({redirect: true});
    }

    componentDidMount() {
        sessionStorage.clear();
    }

    render() {
        return (
                <div style={{marginTop: '3em'}}>
                    {this.state.redirect &&
                                    <Navigate to="/" replace/>}
                    <LoginCard tenantId={this.state.tenantId} img={this.state.logo} onErrors={this._handleErrors} onSuccess={this._handleSuccess}/>
                    {this.state.errores !== '' &&
                                    <Messages text={this.state.errores} onClose={this._handleErrors}/>
                    }
                    <div align='center' style={{transform: 'translate(0%, 100%)', color: 'gray'}}>
                        <i>v {properties.version}</i>
                    </div>
                </div>
                );
    }
};