import React from 'react'
import { Table, TableBody, TableCell, TableRow } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import RemoveIcon from '@mui/icons-material/Remove'

import { EmptyRow } from '../../comp/DataTableComp'
import { LoadingRect } from '../../comp/LoadingComp'
import { TooltipButton } from '../../comp/TooltipButton'
import { FormatCantidad } from '../../utils/Formatter'

export const ListaProductos = ({data, isLoading, aumentar, disminuir, borrar}) => {
    return isLoading
        ? <LoadingRect rows={3} />
        : <Table>
        <TableBody>
            {data.length === 0 && <EmptyRow span={6} />}
            {data.length > 0 &&
            data.map((p, idx)  => 
                <TableRow key={idx}>
                    <TableCell padding="checkbox">
                        <TooltipButton label="TXT_AUMENTAR" onClick={() => aumentar(p.producto.id)}>
                            <AddIcon />
                        </TooltipButton>
                    </TableCell>
                    <TableCell align="right" padding="checkbox">
                        {FormatCantidad(p.cantidad)}
                    </TableCell>
                    <TableCell>
                        <TooltipButton label="TXT_DISMINUIR" onClick={() => disminuir(p.producto.id)} disabled={p.cantidad === 1}>
                            <RemoveIcon />
                        </TooltipButton>
                    </TableCell>
                    <TableCell padding="checkbox"></TableCell>
                    <TableCell>
                        {p.producto.nombre}
                    </TableCell>
                    <TableCell padding="checkbox">
                        <TooltipButton label="TXT_BORRAR" onClick={() => borrar(p.producto.id)}>
                            <DeleteIcon />
                        </TooltipButton>
                    </TableCell>
                </TableRow>
            )}
        </TableBody>
    </Table>
}