import React from "react"
import { DataTableProductosElegidos } from "../../comp/DataTableProductosElegidos.js"
import { ModalDetail } from "../../comp/ModalDetail"
import { Traduccion } from "../../comp/Traduccion"
import { listarConsumos } from "../../services/servConsumos.js"
import { Acciones } from "../../utils/Acciones.js"

export const MesaModalCierre = ({ idMesa, nroMesa, isOpen, onSubmit }) => {

    const [isLoading, setIsLoading] = React.useState(false)
    const [consumos, setConsumos] = React.useState([])

    React.useEffect(() => {
        if (isOpen) {
            setIsLoading(true)
            listarConsumos({ mesaId: idMesa }).then(res => {
                setConsumos(res.data.filter(det => det.activo))
                setIsLoading(false)
            })
        }
    }, [isOpen, idMesa])

    return <ModalDetail translatedTitle customTitle={Traduccion('TXT_CUENTA_MESA_NRO') + ' ' + nroMesa}
        isOpen={isOpen}
        onSubmit={onSubmit}
        maxWidth={'xl'}
        isLoading={isLoading}
        accion={Acciones().SEE}
    >
        <DataTableProductosElegidos data={consumos} isLoading={isLoading} totalizar/>
    </ModalDetail>
}