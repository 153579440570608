import React from 'react'
import {
    CardHeader
} from '@mui/material'

import { Tabs } from '../../comp/Tabs'
import { Traduccion } from '../../comp/Traduccion'
import { Acceso, NivelAcceso } from '../../utils/Seguridad'
import { ReportesCaja } from './cajas'
import { ReportesProductos } from './productos'
import { ReportesClientes } from './clientes'

export const Reportes = () => {

    const check = (view) => Acceso(view, NivelAcceso().CONTROLAR_TODO)

    /* A la altura completa de la pantalla le resto:
     * - 64px de la AppBar
     * - 16px de margin superior y los 16px de inferior de Main
     * - 16px de margin superior y los 16px de inferior del CardHeader
     * - 32px del tamaño del header
     * Así la línea que divide las pestañas del contenido llega hasta el final
     */

    return <>
        <CardHeader title={Traduccion('ViewReportes')} />
        <Tabs ariaLabel='Pestañas'
            height='calc(100vh - 64px - 36px - 36px - 32px)'
            >
            <div label={Traduccion('ViewCajas')} show={check('ViewCajas')}>
                <ReportesCaja/>
            </div>
            <div label={Traduccion('ViewClientes')} show={check('ViewClientes')}>
                <ReportesClientes/>
            </div>
            <div label={Traduccion('ViewProductos')} show={check('ViewProductos')}>
                <ReportesProductos/>
            </div>
        </Tabs>
    </>
}
