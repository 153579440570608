import { dateToJson } from '../utils/DateUtil';
import { GetCall, PostCall, PutCall } from './_serviceHelperApi'

const serviceUrl = '/clientes';

/**
 * @returns obj devuelto: {errores, data}
 */
export const listarClientes = (filters) => {
    var params = [
        { 'key': 'numero', 'val': filters?.numero },
        { 'key': 'nombre', 'val': filters?.nombre },
        { 'key': 'activo', 'val': filters?.activo }
    ];
    return GetCall(serviceUrl, params)
}

export const autocompCliente = (texto) => {
    return GetCall(serviceUrl + '/autocompletar', [{ 'key': 'texto', 'val': texto }]);
}

/**
 * @returns obj devuelto: {errores, data}
 */
export const agregarCliente = (producto) => {
    return PostCall(serviceUrl, producto)
}

/**
 * @returns obj devuelto: {errores, data}
 */
export const modificarCliente = (producto) => {
    return PutCall(serviceUrl, producto)
}

/**
 * @returns obj devuelto: {errores, data}
 */
export const borrarClientes = (listaIds) => {
    return PutCall(serviceUrl + '/borrar', listaIds)
}

/**
 * @returns obj devuelto: {errores, data}
 */
export const reactivarClientes = (listaIds) => {
    return PutCall(serviceUrl + '/reactivar', listaIds)
}

/**
 * @returns lista de transacciones para el cliente seleccionado. Obj devuelto: {errores, data}
 */
export const listarTransacciones = (filters) => {
    var params = [
        { 'key': 'clienteId', 'val': filters?.clienteId },
        { 'key': 'fechaDesde', 'val': dateToJson(filters?.fechaDesde) },
        { 'key': 'fechaHasta', 'val': dateToJson(filters?.fechaHasta) },
        { 'key': 'pageNumber', 'val': filters?.pageNumber },
        { 'key': 'pageSize', 'val': filters?.pageSize }
    ];
    return GetCall(serviceUrl + '/transacciones', params)
}

/**
 * @param {objTransaccion} unaTransaccion {cliente, importe, tipoTransaccion}
 * @returns obj devuelto: {errores, data}
 */
export const agregarTransaccion = (unaTransaccion) => {
    return PostCall(serviceUrl + '/transacciones', unaTransaccion)
}

/************ Reportes ************/

const reportUrl = serviceUrl + '/reportes'

export const reporteEstadoCtaCte = (filters) => {
    var params = []
    return GetCall(reportUrl+'/estadoCtaCte', params)
}

export const reporteDeudores = (filters) => {
    var params = [
        { key: 'pageNumber', val: filters?.pageNumber },
        { key: 'pageSize', val: filters?.pageSize }
    ]
    return GetCall(reportUrl+'/deudores', params)
}

export const reporteGastadores = (filters) => {
    var params = [
        { key: 'periodo', val: dateToJson(filters?.periodo) },
        { key: 'pageNumber', val: filters?.pageNumber },
        { key: 'pageSize', val: filters?.pageSize }
    ]
    return GetCall(reportUrl+'/gastadores', params)
}

export const reporteEvolucionClientes = (filters) => {
    var params = [
        { key: 'agrupa', val: filters?.agrupa }
    ]
    return GetCall(reportUrl+'/evolucionClientes', params)
}