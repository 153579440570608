import { Skeleton } from "@mui/material"
import React from "react"

export const LoadingRect = ({ rows = 1, height = '1.5em' }) => {
    return Array.apply(null, Array(rows)).map((e, idx) =>
        <p key={idx}><Skeleton variant="rect" animation="wave" height={height} /></p>
    )
}

export const LoadingCirc = ({cant = 1, dimension = '1.5em'}) => {
    return Array.apply(null, Array(cant)).map((e, idx) =>
        <p key={idx}><Skeleton variant="circular" animation="wave" width={dimension} height={dimension} /></p>
    ) //<Skeleton variant="circular" width={40} height={40} />
}