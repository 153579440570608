import React from 'react'
import { Config } from '../config/Sesion'
import { DateFormat, FormatDateTo } from './DateUtil'
import { NumericFormat } from 'react-number-format';

export const FormatCodigo = (codigo) => {
    return codigo;
};

export const FormatImporte = (importe) => {
    return <NumericFormat prefix={Config().simboloMonetario + ' '} value={importe}
                  thousandSeparator={Config().separadorDeMiles}
                  decimalSeparator={Config().separadorDecimal}
                  decimalScale={Config().decimalesImporte} fixedDecimalScale={true}
                  displayType={'text'}/>;
};

export const esImporte = (unValor) => Intl.NumberFormat(
    Config().separadorDeMiles === '.' ? 'es-AR' : 'en-US', {
        style: 'currency',
        currency: Config().separadorDeMiles === '.' ? 'ARS' : 'USD',
        minimumFractionDigits: Config().decimalesImporte,
        maximumFractionDigits: Config().decimalesImporte
    }).format(unValor)

export const FormatCantidad = (cantidad) => {
    return <NumericFormat value={cantidad}
                  thousandSeparator={Config().separadorDeMiles}
                  decimalSeparator={Config().separadorDecimal}
                  decimalScale={Config().decimalesCantidad} fixedDecimalScale={true}
                  displayType={'text'}/>;
}

export const esCantidad = (unValor) => Intl.NumberFormat(
    Config().separadorDeMiles === '.' ? 'es-AR' : 'en-US', {
        //style: 'currency',
        //currency: Config().separadorDeMiles === '.' ? 'ARS' : 'USD',
        minimumFractionDigits: Config().decimalesImporte,
        maximumFractionDigits: Config().decimalesImporte
    }).format(unValor)

export const FormatFechaHora = (fecha) => {
    return FormatDateTo(fecha, DateFormat().fechaHoraArgentina);
};

export const FormatPorcentaje = (valor) => {
    return <NumericFormat value={valor} suffix=" %"
                  thousandSeparator={Config().separadorDeMiles}
                  decimalSeparator={Config().separadorDecimal}
                  decimalScale={2} fixedDecimalScale={true}
                  displayType={'text'}/>;
};