import React from 'react'
import Snackbar from '@mui/material/Snackbar'
import { Alert, AlertTitle } from '@mui/material'
import PropTypes from 'prop-types'
import { Traduccion } from './Traduccion'
import { Acciones } from '../utils/Acciones'

export const Messages = ({ resServicio, accion, onClose }) => {

    const [state, setState] = React.useState({
        open: false,
        titulo: '',
        cuerpo: [],
        tipo: 'info'
    })

    const _handleClose = (e) => {
        setState({ ...state, open: false })
        onClose && onClose()
    }

    React.useEffect(() => {
        if (resServicio) {
            let titulo
            let tipo
            if (resServicio.status > 200) {
                titulo = 'MSJ_OPERACION_FALLIDA'
                tipo = 'error'
            } else { //si el servicio devolvió un 200
                if (resServicio.errores) { //si hay msjs de advertencia
                    titulo = 'MSJ_OPERACION_PARCIAL'
                    tipo = 'warning'
                } else { //si todo OK
                    titulo = 'MSJ_OPERACION_EXITOSA'
                    tipo = 'success'
                }
            }
            titulo = Traduccion(titulo)
            let cuerpo = []
            if (![Acciones().ADD, Acciones().SEE].includes(accion) && resServicio.data) {
                cuerpo.push(`${Traduccion('MSJ_REGISTROS_ACTUALIZADOS')}: ${resServicio.data.rows}`)
            }
            if (resServicio.errores?.length) cuerpo = cuerpo.concat(resServicio.errores.map(e => Traduccion(e)))
            setState({ open: true, titulo, tipo, cuerpo })
        }
    }, [resServicio, accion])

    return <>
        <Snackbar open={state.open}
            autoHideDuration={5000}
            onClose={_handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Alert elevation={6}
                variant="filled"
                onClose={_handleClose}
                severity={state.tipo}
            >
                <AlertTitle>{state.titulo}</AlertTitle>
                {
                    state.cuerpo.map((msj, index) => (
                        <React.Fragment key={index}>
                            {msj}
                            {index < state.cuerpo.length - 1 && <br />}
                        </React.Fragment>
                    ))
                }
            </Alert>
        </Snackbar>
    </>

}

Messages.propTypes = {
    onClose: PropTypes.func
}