import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

export const Print = ({id = "print", children}) => {

    /*var printWindow = window.open("", "print", "");
     printWindow.document.write(html);
     printWindow.window.print();
     printWindow.window.close();*/
    /*useReactToPrint({
     content: {component}
     });*/

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    });

    return (
            <div>
                <button id={id} onClick={handlePrint}>Print this out!</button>
                <div ref={componentRef}>{children}</div>
            </div>
            );
};