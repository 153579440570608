import React from 'react'
import { Card, CardContent, Grid } from '@mui/material'
import { AutocompleteProducto } from '../../../../comp/AutocompleteProducto'
import { DataTableProductosElegidos } from '../../../../comp/DataTableProductosElegidos'
import { InputCantidad } from '../../../../comp/Input'
import { Traduccion } from '../../../../comp/Traduccion'

import { getCajaOperada } from '../../../../config/Sesion'

import { DatosCaja } from '../DatosCaja'
import { Resumen } from './Resumen'

import { Ok } from '../../../../comp/Message'
import { ModalRegistrarVenta } from '../contenido'
import { useNavigate } from 'react-router-dom'

export const Nuevo = () => {

    const cajaOperada = getCajaOperada()
    const [detallesProductos, setDetallesProductos] = React.useState([])
    const [cantidad, setCantidad] = React.useState(1)
    const [descuentoEfectivo, setDescuentoEfectivo] = React.useState(false)
    const [isOpenModalCheckOut, setIsOpenModalCheckOut] = React.useState(false)

    const navigate = useNavigate()

    const _handleIrAMovimientos = () => navigate('/cajas/movimientos')
    const _handleChangeCantidad = (cant) => setCantidad(cant)
    const _irAlPaso = () => setIsOpenModalCheckOut(true)

    const _handleDeleteProducto = (submit, aryObj) => setDetallesProductos(
        detallesProductos.filter(det => aryObj.indexOf(det.producto.id) === -1)
    )

    const _handleSelectProducto = (seleccion) => {
        if (seleccion) {
            _agregarProductoALista(seleccion);
            setCantidad(1)
        }
    }

    const _agregarProductoALista = (prod) => {
        let aux = detallesProductos.map(e => e)
        let elem = aux.find(det => det.producto.id === prod.id)
        elem
            ? elem.cantidad += cantidad
            : aux.push({'producto': prod, 'cantidad': cantidad, 'precio': prod.precioVenta})
        setDetallesProductos(aux)
        //this._actualizarProductosPorDescuentos(detallesProductos)
    }

    //factorEfectivo = 0.85
    /*_actualizarProductosPorDescuentos = (detalles) => {
        if ([1,7,8].includes(Tenant().id)) {
            let factor = 1
            if (this.state.detalleCobros.find(e => e.tipoCobro.id === 1).importe > 0) {
                factor = this.factorEfectivo
            }
            let detallesProductos = detalles ? detalles : this.state.detallesProductos
            detallesProductos.forEach(det => {
                det.precio = det.producto.precioVenta * factor
            })
            this.setState({detallesProductos, descuentoEfectivo: factor !== 1});
        }
    }*/

    const _totalVendido = () =>
        !detallesProductos.length 
            ? 0
            : detallesProductos
                .map(a => a.precio * a.cantidad)
                .reduce((a, b) => a + b)

    const _totalCobrado = () => 0
    const _vuelto = () => 0

    const _handleModalCheckoutSubmit = (isSubmitted, datos) => {
        setIsOpenModalCheckOut(false)
        if (isSubmitted) {
            setDetallesProductos([])
            setCantidad(1)
        }
    }

    return  <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={3} lg={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <DatosCaja datosCaja={cajaOperada}
                                       handleVolver={_handleIrAMovimientos}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Resumen totalVendido={_totalVendido()}
                                     totalCobrado={_totalCobrado()}
                                     vuelto={_vuelto()}
                                     stepHandler={_irAlPaso}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={9} lg={10}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item xs={8} lg={10}>
                                            <AutocompleteProducto id='autocompProductos' onSelect={_handleSelectProducto} clearOnSelect autoFocus/>
                                        </Grid>
                                        <Grid item xs={4} lg={2}>
                                            <InputCantidad id='cantidadProductoVenta'
                                                            label={Traduccion('TXT_CANTIDAD')} 
                                                            value={cantidad}
                                                            onChange={_handleChangeCantidad}/>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <DataTableProductosElegidos data={detallesProductos}
                                                        handleDelete={_handleDeleteProducto}
                                                        isLoading={false}/>
                        </Grid>
                    </Grid>
                    
                    {descuentoEfectivo &&
                        <Grid item xs={12}>
                            <br/>
                            <Ok>{Traduccion('MSJ_APLICADO_DESCUENTO_EFECTIVO')}</Ok>
                        </Grid>
                    }
                </Grid>
            </Grid>
                            
            <ModalRegistrarVenta isOpen={isOpenModalCheckOut}
                                cajaOperada={cajaOperada}
                                detalleProductos={detallesProductos}
                                onSubmit={_handleModalCheckoutSubmit} />
            

        </>

}