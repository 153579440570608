import React from 'react'
import { Button, Fade, LinearProgress } from '@mui/material'
import { Modal } from './Modal'
import { Traduccion } from './Traduccion'


export const ModalWizard = ({ children, isOpen, onSubmit, maxWidth = 'xs', title, isLoading = false, steps, nextDisabledSteps }) => {

    const [currentStep, setCurrentStep] = React.useState(0)
    const isOnFirstStep = () => currentStep === 0
    const isOnLastStep = () => currentStep === steps - 1
    const isNextBlocked = () => nextDisabledSteps.includes(currentStep + 1)

    const _handleCancel = () => {
        onSubmit(false)
    }

    const _handleBack = () => {
        let current = currentStep
        setCurrentStep(current - 1)
    }

    const _handleSubmit = (e) => {
        e.preventDefault();
        if (!isOnLastStep()) {
            let current = currentStep + 1
            setCurrentStep(current)
        } else {
            onSubmit(true)
        }
    }

    const actions =
        <div>
            <Button onClick={_handleCancel} color="primary" disabled={isLoading || isOnLastStep()}>
                {Traduccion('TXT_CANCELAR')}
            </Button>
            <Button onClick={_handleBack} color="primary" disabled={isLoading || isOnFirstStep() || isOnLastStep()}>
                {Traduccion('TXT_ANTERIOR')}
            </Button>
            <Button onClick={e => _handleSubmit(e)} color="primary" variant="contained" disabled={isLoading || isNextBlocked()}>
                {Traduccion(isOnLastStep() ? 'TXT_FINALIZAR' : 'TXT_SIGUIENTE')}
            </Button>
        </div>

    React.useEffect(() => {
        if (isOpen) {
            setCurrentStep(0)
        }
    },[isOpen])

    return <Modal isOpen={isOpen}
        actions={actions}
        title={Traduccion(title)}
        onClose={_handleCancel}
        maxWidth={maxWidth}>
        <form onSubmit={e => _handleSubmit(e)}>
            {
                children.forEach((child, idx) => {
                    <Fade key={idx} in={currentStep === idx}>{child}</Fade>
                })
            }
            <Button type="submit" style={{ display: 'none' }} />
        </form>
        <LinearProgress variant="determinate" value={currentStep * 100 / (steps - 1)} />    
    </Modal>


}