import React from "react"
import { Card, CardContent, CardHeader, Grid, Stack } from '@mui/material'
import { Traduccion } from "../../comp/Traduccion"
import { 
    reporteVentasConcretadas,
    reporteProductosMasYMenosVendidos,
    reporteIngresosPorUsuario,
    reporteIngresosPorFormaDePago
} from "../../services/servCajas"
import { esImporte } from "../../utils/Formatter"
import { Normal } from '../../comp/Texto'
import { GraficoBarras, GraficoLineas, GraficoTorta } from "../../comp/Graficos"
import { dateToIntPeriod, dateToJson } from "../../utils/DateUtil"
import { InputPeriodo } from "../../comp/InputFecha"

export const ReportesCaja = () => {

    let defaultPeriodo = new Date()
    defaultPeriodo.setDate(1)

    const [periodo, setPeriodo] = React.useState(defaultPeriodo) //busco datos del mes en curso
    const [agrupa, setAgrupa] = React.useState('MM') //agrupo la consulta por Mes (MM) o por día (DD)
    
    const [resServCantidadImporte, setResServCantidadImporte] = React.useState([])
    const [isServCantidadImporteLoading, setIsServCantidadImporteLoading] = React.useState(false)

    const [resServProductos, setResServProductos] = React.useState([])
    const [isServProductosLoading, setIsServProductosLoading] = React.useState(false)

    const [resServUsuarios, setResServUsuarios] = React.useState([])
    const [isServUsuariosLoading, setIsServUsuariosLoading] = React.useState(false)

    const [resServFormaDePago, setResServFormaDePago] = React.useState([[]])
    const [isServFormaDePagoLoading, setIsServFormaDePagoLoading] = React.useState(false)

    const periodoInt = (unPeriodo) => dateToIntPeriod(unPeriodo)

    const periodoAnteriorInt = (unPeriodo) => {
        let periodoAnterior = new Date(unPeriodo)
        periodoAnterior.setDate(1)
        periodoAnterior.setMonth(periodoAnterior.getMonth()-1)
        return dateToIntPeriod(periodoAnterior)
    }

    /*** funciones para VENTAS ***/

    const meses = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    //const dias = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]

    const ventasConcretadas = () => meses.map(e => {
        let aux = {}
        aux.mes = e
        aux[periodo.getFullYear()] = resServCantidadImporte.find(el => el.periodo === (periodo.getFullYear()*100) + e)?.cantidadVentas ?? null
        aux[periodo.getFullYear()-1] = resServCantidadImporte.find(el => el.periodo === ((periodo.getFullYear()-1)*100) + e)?.cantidadVentas ?? null
        return aux
    })

    const importesVendidos = () => meses.map(e => {
        let aux = {}
        aux.mes = e
        aux[periodo.getFullYear()] = resServCantidadImporte.find(el => el.periodo === (periodo.getFullYear()*100) + e)?.importeVentas ?? null
        aux[periodo.getFullYear()-1] = resServCantidadImporte.find(el => el.periodo === ((periodo.getFullYear()-1)*100) + e)?.importeVentas ?? null
        return aux
    })

    const dataKeysAndLabels = () => {
        let aux = {}
        aux[periodo.getFullYear()] = (periodo.getFullYear()).toString()
        aux[periodo.getFullYear()-1] = (periodo.getFullYear()-1).toString()
        return aux
    }

    const formatLabelMeses = (nroMes) => Traduccion(`TXT_MES_${nroMes}`).substring(0,3)

    /*** funciones para PRODUCTOS ***/

    const formatDatosProductos = (datosProductos) => {
        let aux = {}
        aux.masVendidos = datosProductos.masVendidos.map(e => ({
            productoId: e.producto.id,
            productoNombre: e.producto.nombre,
            cantidad: e.cantidad
        })) ?? []
        aux.menosVendidos = datosProductos.menosVendidos.map(e => ({
            productoId: e.producto.id,
            productoNombre: e.producto.nombre,
            cantidad: e.cantidad
        })) ?? []
        return aux
    }

    const formatLabelProductos = (labelValue) =>
        (resServProductos.masVendidos ?? [])
            .concat(resServProductos.menosVendidos ?? [])
            .find(p => p.productoId === labelValue)?.productoNombre
    
    const formatDataProductos = (dataValue) =>
        `${dataValue} ${Traduccion(dataValue === 1 ? 'TXT_UNIDAD' : 'TXT_UNIDADES')}`

    const dataKeysAndLabelsProductos = {
        cantidad: Traduccion('TXT_CANTIDAD')
    }

    /*** funciones para USUARIOS ***/

    const datosVentasPorUsuario = () => {
        if (!resServUsuarios.length) return []
        //armo lista sin usuarios duplicados
        let aux = Array.from(new Set(resServUsuarios.map(e => JSON.stringify(e.usuario))))
            .map(e => JSON.parse(e))
            .map(e => ({usuarioId: e.id, usuarioNombre: e.nombre}))
        //para cada usuario les completo los datos de cada periodo
        aux.forEach(user => {
            user[periodoInt(periodo)] = resServUsuarios.find(e => e.periodo === periodoInt(periodo) && e.usuario.id === user.usuarioId)?.total ?? 0
            user[periodoAnteriorInt(periodo)] = resServUsuarios.find(e => e.periodo === periodoAnteriorInt(periodo) && e.usuario.id === user.usuarioId)?.total ?? 0
        })
        return aux
    }

    const formatLabelUsuarios = (labelValue) => 
        (resServUsuarios.find(u => u.usuario.id === labelValue)?.usuario.nombre)?.split(' ').join('\n')
    
    const formatDataUsuarios = (dataValue) => esImporte(dataValue)

    const dataKeysAndLabelsUsuarios = () => {
        let aux = {}
        aux[periodoInt(periodo)] = periodoInt(periodo).toString().substring(0,4) + '-' + periodoInt(periodo).toString().substring(4)
        aux[periodoAnteriorInt(periodo)] = periodoAnteriorInt(periodo).toString().substring(0,4) + '-' + periodoAnteriorInt(periodo).toString().substring(4)
        return aux
    }

    /*** funciones para FORMAS DE PAGO ***/

    const formatDatosFormasDePago = (unArray) => {
        let aux = []
        aux[0] = unArray.map(e => ({formaDePagoId: e.formaDePago.id, formaDePagoNombre: e.formaDePago.nombre, importe: e.importe}))
        return aux
    }

    const formatLabelFormaDePago = (labelValue, idx) =>
        (
            labelValue > 1
                ? resServFormaDePago[idx].find(f => f.formaDePagoId === labelValue).formaDePagoNombre
                : Traduccion(resServFormaDePago[idx].find(f => f.formaDePagoId === labelValue).formaDePagoNombre)
        ).split(' ').join('\n')
    
    const formatDataFormaDePago = (dataValue) => esImporte(dataValue)

    const dataKeysAndLabelsFormaDePago = {
        0: 'importe'
    }

    //inicializo datos de gráficas
    React.useEffect(() => {
        let auxPeriodo = dateToJson(periodo)
        setIsServCantidadImporteLoading(true)
        reporteVentasConcretadas({ agrupa }).then(res => {
            setResServCantidadImporte(res.datos)
            setIsServCantidadImporteLoading(false)
        })
        setIsServProductosLoading(true)
        reporteProductosMasYMenosVendidos({periodo: auxPeriodo}).then(res => {
            setResServProductos(formatDatosProductos(res.datos))
            setIsServProductosLoading(false)
        })
        setIsServUsuariosLoading(true)
        reporteIngresosPorUsuario({periodo: auxPeriodo}).then(res => {
            setResServUsuarios(res.datos)
            setIsServUsuariosLoading(false)
        })
        setIsServFormaDePagoLoading(true)
        reporteIngresosPorFormaDePago({periodo: auxPeriodo}).then(res => {
            setResServFormaDePago(formatDatosFormasDePago(res.datos))
            setIsServFormaDePagoLoading(false)
        })
    }, [agrupa, periodo])

    return <>
        <Stack direction='row' alignItems='center' spacing={2} mt={2}>
            <Normal>{Traduccion('TXT_ANALISIS_DE')}</Normal>
            <div><InputPeriodo label={Traduccion('TXT_PERIODO')} value={periodo} onChange={setPeriodo}/></div>
        </Stack>
        <br/>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={'auto'}>
                <Card>
                    <CardHeader title={Traduccion('TXT_PRODUCTOS_MAS_VENDIDOS')} />
                    <CardContent>
                        <GraficoBarras dataset={resServProductos.masVendidos ?? []}
                            isLoading={isServProductosLoading}
                            labelKey='productoId'
                            labelFormatter={formatLabelProductos}
                            dataAxisDesc={Traduccion('TXT_CANTIDAD_UN')}
                            dataKeysAndLabels={dataKeysAndLabelsProductos}
                            dataFormatter={formatDataProductos}
                            horizontal
                            />
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={'auto'}>
                <Card>
                    <CardHeader title={Traduccion('TXT_PRODUCTOS_MENOS_VENDIDOS')} />
                    <CardContent>
                        <GraficoBarras dataset={resServProductos.menosVendidos ?? []}
                            isLoading={isServProductosLoading}
                            labelKey='productoId'
                            labelFormatter={formatLabelProductos}
                            dataAxisDesc={Traduccion('TXT_CANTIDAD_UN')}
                            dataKeysAndLabels={dataKeysAndLabelsProductos}
                            dataFormatter={formatDataProductos}
                            horizontal
                            />
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={'auto'}>
                <Card>
                    <CardHeader title={Traduccion('TXT_INGRESOS_POR_USUARIO')} />
                    <CardContent>
                        <GraficoBarras
                            dataset={datosVentasPorUsuario()}
                            isLoading={isServUsuariosLoading}
                            labelKey='usuarioId'
                            labelFormatter={formatLabelUsuarios}
                            dataAxisDesc={Traduccion('TXT_IMPORTE')}
                            dataKeysAndLabels={dataKeysAndLabelsUsuarios()}
                            dataFormatter={formatDataUsuarios}
                            />
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={'auto'}>
                <Card>
                    <CardHeader title={Traduccion('TXT_INGRESOS_POR_FORMA_DE_PAGO')} />
                    <CardContent>
                        <GraficoTorta
                            dataset={resServFormaDePago}
                            isLoading={isServFormaDePagoLoading}
                            labelKey='formaDePagoId'
                            labelFormatter={formatLabelFormaDePago}
                            dataKeysAndLabels={dataKeysAndLabelsFormaDePago}
                            dataFormatter={formatDataFormaDePago}
                            />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
        <br/>
        <br/>
        <Normal>{Traduccion('TXT_ANALISIS_HISTORICO')}</Normal>
        <br/>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={'auto'}>
                <Card>
                    <CardHeader title={Traduccion('TXT_CANTIDAD_VENTAS_CONCRETADAS')} />
                    <CardContent>
                        <GraficoLineas 
                            dataset={ventasConcretadas()}
                            isLoading={isServCantidadImporteLoading}
                            labelKey='mes'
                            labelFormatter={formatLabelMeses}
                            dataAxisDesc={Traduccion('TXT_CANTIDAD')}
                            dataKeysAndLabels={dataKeysAndLabels()}
                        />
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={'auto'}>
                <Card>
                    <CardHeader title={Traduccion('TXT_INGRESOS_POR_VENTAS')} />
                    <CardContent>
                        <GraficoLineas 
                            dataset={importesVendidos()}
                            isLoading={isServCantidadImporteLoading}
                            labelKey='mes'
                            labelFormatter={formatLabelMeses}
                            dataKeysAndLabels={dataKeysAndLabels()}
                            dataFormatter={esImporte}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    </>
}