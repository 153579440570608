import React from 'react'
import { Button } from '@mui/material'
import { Modal } from './Modal'
import { Traduccion } from './Traduccion'
import { Acciones } from '../utils/Acciones'

export const ModalDetail = ({children, isOpen, onSubmit, accion, maxWidth = 'xs', customTitle, isLoading = false, translatedTitle = false, masAcciones = []}) => {

    const _handleCancel = () => {
        onSubmit(false)
    }

    const _handleSubmit = (e) => {
        e.preventDefault()
        onSubmit(true)
    }

    const getTitleText = () => {
        let auxTitle = customTitle ? customTitle : accion
        return translatedTitle ? auxTitle : Traduccion(auxTitle)
    }

    const actions = (
            <div>
                <Button onClick={_handleCancel} color="primary" disabled={isLoading}>
                    {Traduccion('TXT_CANCELAR')}
                </Button>
                {masAcciones.length > 0 &&
                    masAcciones.map((otraAccion, idx) => {
                return <Button key={idx} onClick={otraAccion.funcion} color="primary" disabled={isLoading}>
                            {Traduccion(otraAccion.texto)}
                        </Button>
                    })
                }
                { accion !== Acciones().SEE &&
                            <Button onClick={e => _handleSubmit(e)} color="primary" variant="contained" disabled={isLoading}>
                                {Traduccion('TXT_GUARDAR')}
                            </Button>
                }
            </div>
            )

    return (
            <Modal isOpen={isOpen}
                   actions={actions}
                   title={getTitleText()}
                   onClose={_handleCancel}
                   maxWidth={maxWidth}>
                <form onSubmit={e => _handleSubmit(e)}>
                    {children}
                    { accion !== Acciones().SEE &&
                            <Button type="submit" style={{display: 'none'}}/>
                    }
                </form>
            </Modal>
            )

}