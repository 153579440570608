import React from 'react'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button';

const Mensaje = ({btnText, btnFunction = false, severidad, children}) => {
    return <Alert severity={severidad}
                    action={<ActionButton label={btnText} onClick={btnFunction}/>}>
                    {children}
            </Alert>;
};

const ActionButton = ({label, onClick}) => {
    return label ? <Button color="inherit" size="small" onClick={() => onClick()}>{label}</Button> : null;
};

export const Error = ({children, ...Props}) => {
    return <Mensaje severidad="error" {...Props}>{children}</Mensaje>;
};

export const Warn = ({children, ...Props}) => {
    return <Mensaje severidad="warning" {...Props}>{children}</Mensaje>;
};

export const Info = ({children, ...Props}) => {
    return <Mensaje severidad="info" {...Props}>{children}</Mensaje>;
};

export const Ok = ({children, ...Props}) => {
    return <Mensaje severidad="success" {...Props}>{children}</Mensaje>;
};