import React, {Component} from 'react'
import { Routes, Route } from 'react-router-dom'
import { Login } from './pages/login'
import { Main } from './pages/main'
import { PrivateRoute } from './comp/PrivateRoute'
import { SesionActiva } from './config/Sesion'
//import { Messages } from './comp/Messages';

import './App.css';

export default class App extends Component {

    render() {
        
        //const url = URL(document.location);
        //url.searchParams.has('param') determina si el parametro existe en la url
        //url.searchParams.get('param') obtiene el valor de algun parametro

        return (
                <div className="App">
                    <Routes>
                        <Route exact path="/login" element={<Login/>}/>
                        <Route element={<PrivateRoute isAuthenticated={SesionActiva()} />}>
                            <Route path='*' element={<Main/>} />
                        </Route>
                    </Routes>
                </div>
                );
    }

}
;