import { Grid } from '@mui/material'
import React from "react"
import { ModalDetail } from "../../comp/ModalDetail"
import { Traduccion } from "../../comp/Traduccion"

import { DetalleFormasDeCobro } from "../cajas/formasDeCobro/detalleFormasDeCobro"
import { Resumen } from "../cajas/movimientos/nuevo/Resumen"

import { agregar } from '../../services/servCajasMovimientos'
import { listarConsumos } from "../../services/servConsumos"
import { getCajaOperada } from "../../config/Sesion"

export const MesaModalCobro = ({ idMesa, nroMesa, idCab, isOpen, onSubmit }) => {

    const [consumos, setConsumos] = React.useState([])
    const [isLoadingConsumos, setIsLoadingConsumos] = React.useState(false)
    const [cobros, setCobros] = React.useState([])
    const [isLoadingFormasCobro, setIsLoadingFormasCobro] = React.useState(false)

    const isLoading = () => isLoadingConsumos || isLoadingFormasCobro

    const afterSubmitModal = (res) => {
        if (!res) {
            onSubmit(res)
            return
        }
        let cobroDeCaja = {
            caja: getCajaOperada(),
            motivoMovimiento: { id: -2 }, // motivo de "Consumo en mesas"
            detallesProductos: consumos,
            detallesCobros: cobros,
            consumoMesaId: idCab
        }
        agregar(cobroDeCaja).then(data => {
            if (Array.isArray(data)) { //si hubo error
                alert(
                    Traduccion('MSJ_OPERACION_FALLIDA') + ':\r* '
                    + data.map(d => Traduccion(d.codigo)).join('\r* ')
                )
                return
            }
            onSubmit(res)
        })
    }

    React.useEffect(() => {
        if (isOpen) {
            setIsLoadingConsumos(true)
            listarConsumos({ mesaId: idMesa }).then(res => {
                setConsumos(res.data.filter(det => det.activo))
                setIsLoadingConsumos(false)
            })
        }
    }, [isOpen, idMesa])

    return <ModalDetail translatedTitle 
        customTitle={Traduccion('TXT_COBRAR_MESA_NRO') + ' ' + nroMesa}
        isOpen={isOpen}
        onSubmit={afterSubmitModal}
        maxWidth={'sm'}
        isLoading={isLoading()}
    >
        <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
                <Resumen readOnly
                    totalVendido={consumos.map(e => e.cantidad * e.precio).reduce((a, b) => a + b, 0)}
                    totalCobrado={cobros.map(e => e.importe).reduce((a, b) => a + b, 0)} />
            </Grid>
            <Grid item xs={12} sm={8}>
                <DetalleFormasDeCobro dataChangeCallback={setCobros}
                    isLoadingCallback={setIsLoadingFormasCobro} />
            </Grid>
        </Grid>
    </ModalDetail>
}