import React from "react"
import PropTypes from 'prop-types'
import { Box, Tab, Tabs as TabsMUI } from "@mui/material"
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

const TabPanel = ({ children, value, index, ...other }) => {

    const theme = useTheme()
    const isSm = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            style={{ flexGrow: 1, overflow: 'auto' }}
            {...other}
        >
            {value === index && (
                <Box sx={{
                    pl: isSm ? 0 : 2,
                    pt: isSm ? 1 : 0
                }}>
                    {React.cloneElement(children, { show: undefined })}
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
}

const a11yProps = (index) => {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    }
}

export const Tabs = ({ariaLabel='', height='', children}) => {

    const [value, setValue] = React.useState(0)
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    }

    const theme = useTheme()
    const isSm = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <Box sx={{ flexGrow: 1, display: 'flex', height, flexDirection: isSm ? 'column' : 'row' }}>
            <TabsMUI
                orientation={isSm ? 'horizontal' : 'vertical'}
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label={ariaLabel}
                sx={{
                    borderRight: isSm ? 0 : 1, 
                    borderBottom: isSm ? 1 : 0, 
                    borderColor: 'divider',
                    minWidth: 100
                }}
            >
                {React.Children.map(children.filter(e => e.props.show), (child, index) => (
                    <Tab label={child.props.label} sx={{ width: 'auto', minWidth: 0, maxWidth: 2000, flexShrink: 0}} {...a11yProps(index)} />
                ))}
            </TabsMUI>
            {React.Children.map(children.filter(e => e.props.show), (child, index) => (
                <TabPanel value={value} index={index}>
                    {child}
                </TabPanel>
            ))}
        </Box>
    )
}