import { getEncodedToken } from '../config/Sesion'

const getHeaderConfig = (method, body = undefined) => {
    let config = {
        method, 
        headers: { 'X-Bearer': getEncodedToken() }
    }
    if (body) {
        config.headers['Content-Type'] = 'application/json'
        config.body = JSON.stringify(body)
    }
    return config
}

const fetchCall = async (url, config) => {
    const response = await fetch(url, config)
    try {
        const res = await response.json()
        res.status = response.status
        return res
    } catch (err) {
        alert('Problema de conexión:\r\nrevisar conectividad a internet')
        console.error(err)
        return { status: 500, errores: ['ERR_INESPERADO'] }
    }
}

const getQueryParams = (filters) => {
    if (filters === undefined) {
        return ''
    }
    let filledParams = []
    for (const prop in filters) {
        //si el val no es undefined, null o ''
        if (filters[prop] !== undefined && filters[prop] !== null && filters[prop] !== '') {
            if (Array.isArray(filters[prop])) {
                filters[prop].forEach(val => filledParams.push(`${prop}=${val}`))
            } else {
                filledParams.push(`${prop}=${filters[prop]}`)
            }
        }
    }
    return filledParams.length ? '?' + filledParams.join('&') : ''
}

export const GetCall = async (url, params) =>
    await fetchCall(url + getQueryParams(params), getHeaderConfig('GET'))

export const PostCall = async (url, body) =>
    await fetchCall(url, getHeaderConfig('POST', body))

export const PutCall = async (url, body) =>
    await fetchCall(url, getHeaderConfig('PUT', body))

export const DeleteCall = async (url, params) =>
    await fetchCall(url + getQueryParams(params), getHeaderConfig('DELETE'))

export const PatchCall = async (url, body) =>
    await fetchCall(url, getHeaderConfig('PATCH', body))