import React from "react"
import { Grid } from '@mui/material'
import { InputTexto } from "../../../comp/Input"
import { ModalDetail } from "../../../comp/ModalDetail"
import { Traduccion } from "../../../comp/Traduccion"

export const Detalle = ({ accion, isOpen, onSubmit, seleccion = {} }) => {

    const [nombre, setNombre] = React.useState(seleccion.descripcion)
    const [activo, setActivo] = React.useState(seleccion.activo)
    const [formSubmited, setFormSubmited] = React.useState(false)

    const obligatoriosConValor = () => nombre !== undefined && nombre !== null && nombre.length

    const handleSubmit = (res) => {
        if (res && !obligatoriosConValor()) {
            setFormSubmited(true)
            return //si intento enviar form y los obligatorios no están completos interrumpo
        }
        onSubmit(res, { descripcion: nombre, activo: activo });
    }

    React.useEffect(() => {
        if (isOpen) {
            setNombre(seleccion.descripcion);
            setActivo(seleccion.activo)
            setFormSubmited(false)
        }
    }, [seleccion, isOpen]);

    return <ModalDetail accion={accion} isOpen={isOpen} onSubmit={handleSubmit}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <InputTexto label={Traduccion('TXT_NOMBRE')}
                    value={nombre}
                    onChange={e => setNombre(e)}
                    validarSubmit={formSubmited}
                    requerido autoFocus />
            </Grid>
        </Grid>
    </ModalDetail>
}