import React from 'react'
import { InputSelect as CompInputSelect } from '../../../comp/InputSelect'
import { Traduccion } from '../../../comp/Traduccion'

export const InputSelect = ({id, categoriaId = null, listaCategorias = [], isLoading = false, onChange, requerido, editable, validarSubmit}) => {
/*
    React.useEffect(() => {
    }, [])
*/
    const getListaCategorias = () => {
        let aux = listaCategorias.map(e => {return {label: e.nombre, value: e.id}})
        if (requerido) {
            aux.length > 1 && aux.unshift({label: Traduccion('TXT_SELECCIONE'), value: ''})
        } else {
            aux.unshift({label: Traduccion('TXT_SELECCION_TODAS'), value: ''})
        }
        return aux
    }

    const getCategoriaSeleccionada = () => {
        let aux = ''
        if (categoriaId) {
            aux = categoriaId
        }
        return aux
    }

    return <CompInputSelect id={id}
                     label={Traduccion('TXT_CATEGORIA')}
                     value={getCategoriaSeleccionada()}
                     onChange={v => onChange(v === '' ? null : v)}
                     options={getListaCategorias()}
                     isLoading={isLoading}
                     editable={editable}
                     requerido={requerido}
                     validarSubmit={validarSubmit}/>
}