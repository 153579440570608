import React from 'react';
import { Button } from '@mui/material';
import { Modal } from './Modal';
import { Traduccion } from './Traduccion';

export function ModalSearch({onSubmit, isOpen, children}) {

    const _handleCancel = () => {
        onSubmit(false);
    };

    const _handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(true);
    };

    const actions = (
            <div key="dialog-actions">
                <Button onClick={_handleCancel} color="primary">
                    {Traduccion('TXT_CANCELAR')}
                </Button>
                <Button onClick={_handleSubmit} color="primary" variant="contained">
                    {Traduccion('TXT_FILTRAR')}
                </Button>
            </div>
            );

    return (
            <Modal isOpen={isOpen}
                   actions={actions}
                   title={Traduccion('TXT_FILTRAR')}
                   onClose={_handleCancel}
                   maxWidth="xs"
                   >
                <form onSubmit={e => _handleSubmit(e)}>
                    {children}
                    <Button type="submit" style={{display:'none'}}/>
                </form>
            </Modal>
            );

}
;