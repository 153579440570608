import React from 'react';
import { AutocompleteComp } from './Autocomplete';
import { autocompCliente } from '../services/servClientes';

export const AutocompleteCliente = ({id, onSelect, clearOnSelect, autoFocus, seleccion, editable, requerido, validarSubmit}) => {
    
    const itemLabel = (obj) => obj 
        ? obj.numero + ' - ' + obj.nombre
        : ''

    return <AutocompleteComp id={id} serviceCall={autocompCliente} responseData='data' label="TXT_CLIENTE"
                      itemLabel={itemLabel} onSelect={onSelect} clearOnSelect={clearOnSelect} autoFocus={autoFocus} seleccion={seleccion}
                      editable={editable} requerido={requerido} validarSubmit={validarSubmit}/>
};