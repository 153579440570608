import React from 'react'
import { Button, Card, CardActions, CardHeader, CardMedia, Grid } from '@mui/material'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import { Switcher } from '../../comp/Switcher'
import { ModalWizard } from '../../comp/ModalWizard'
import { Normal } from '../../comp/Texto'
import { Traduccion } from '../../comp/Traduccion'
import { getCSRAfip, enableAfip } from '../../services/servAfipCertificados'
import { InputFile, InputTexto } from '../../comp/Input'

const styles = {
    imgModulo: {
        height: '12.5em'
    },
    imgSteps: {
        height: '14.3em'
    }
}

export const HabilitarAfipCard = ({ precio, activado, onFinishCallback }) => {

    const [showHabilitarAfip, setShowHabilitarAfip] = React.useState(false)

    const onFinishHabilitarAfip = (res) => {
        setShowHabilitarAfip(false)
        if (res) {
            onFinishCallback()
        }
    }

    return <div>
        <Card>
            <CardHeader title={Traduccion('MODULO_AFIP')} />
            <CardMedia sx={styles.imgModulo}
                image="/afip/LogoAFIP.png" />
            <CardActions>
                <Button onClick={() => setShowHabilitarAfip(true)}
                    color="primary"
                    variant='contained'
                    disabled={activado}>
                    {Traduccion('TXT_ACTIVAR')}
                </Button>
            </CardActions>
        </Card>
        <ModalHabilitar isOpen={showHabilitarAfip} onFinish={onFinishHabilitarAfip} />
    </div>
}

const ModalHabilitar = ({ isOpen, onFinish }) => {

    const [nextDisabledSteps, setNextDisabledSteps] = React.useState([])

    const _handleStep1FormChange = (okWithTOS) => {
        checkStepCondition(1, okWithTOS)
    }

    const _handleStep5FormChange = (wasCsrDownloaded) => {
        checkStepCondition(5, wasCsrDownloaded)
    }

    const _handleStep8FormChange = (wasCrtSaved) => {
        checkStepCondition(8, wasCrtSaved)
    }

    const _handleSubmit = (res) => {
        onFinish(res)
    }

    const checkStepCondition = (stepNumber, validCondition) => {
        let disabled = nextDisabledSteps.filter(e => e !== stepNumber)
        if (!validCondition) {
            disabled.push(stepNumber)
        }
        setNextDisabledSteps(disabled)
    }

    React.useEffect(() => {
        if (isOpen) {
            setNextDisabledSteps([1, 5, 8])
        }
    }, [isOpen])

    const steps = () => {
        return [
            <Step1Afip key={1} onFormChange={_handleStep1FormChange} />,
            <Step2Afip key={2} />,
            <SimpleStep key={3} text='CFG_AFIP_PASO_3' imageRoute='/afip/actPaso3.png' />,
            <SimpleStep key={4} text='CFG_AFIP_PASO_4' imageRoute='/afip/actPaso4.png' />,
            <Step5Afip key={5} onFormChange={_handleStep5FormChange} />,
            <SimpleStep key={6} text='CFG_AFIP_PASO_6' imageRoute='/afip/actPaso6.png' />,
            <SimpleStep key={7} text='CFG_AFIP_PASO_7' imageRoute='/afip/actPaso7.png' />,
            <Step8Afip key={8} onFormChange={_handleStep8FormChange} />,
            <SimpleStep key={9} text='CFG_AFIP_PASO_9' imageRoute='/afip/actPaso9.png' />,
            <SimpleStep key={10} text='CFG_AFIP_PASO_10' imageRoute='/afip/actPaso10.png' />,
            <SimpleStep key={11} text='CFG_AFIP_PASO_11' imageRoute='/afip/actPaso11.png' />,
            <SimpleStep key={12} text='CFG_AFIP_PASO_12' imageRoute='/afip/actPaso12.png' />,
            <SimpleStep key={13} text='CFG_AFIP_PASO_13' imageRoute='/afip/actPaso13.png' />,
            <Step14Afip key={14} />
        ]
    }

    return <ModalWizard isOpen={isOpen}
        onSubmit={_handleSubmit}
        title='TXT_ASISTENTE_CONFIGURACION'
        steps={steps().length}
        nextDisabledSteps={nextDisabledSteps}
        maxWidth='sm'>
        {
            steps()
        }
    </ModalWizard>
}

/******** STEPS ********/

const Step1Afip = ({ onFormChange }) => {

    const [okWithTOS, setOkWithTOS] = React.useState(false);

    const handleChange = (isOk) => {
        setOkWithTOS(isOk)
        onFormChange(isOk)
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Normal>{Traduccion('CFG_AFIP_PASO_1')}.</Normal>
            </Grid>
            <Grid item xs={12}>
                <Switcher label={Traduccion('TXT_ACEPTO_COSTOS')}
                    value={okWithTOS}
                    onChange={e => handleChange(e)} />
            </Grid>
        </Grid>
    )
}

const Step2Afip = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Normal>{Traduccion('CFG_AFIP_PASO_2')}: <a href='https://www.afip.gob.ar' rel='noopener noreferrer' target={'_blank'}>https://www.afip.gob.ar</a>.</Normal>
            </Grid>
            <Grid item xs={12}>
                <CardMedia image="/afip/actPaso2.png" sx={styles.imgSteps} />
            </Grid>
        </Grid>
    )
}

const Step5Afip = ({ onFormChange }) => {

    const descargarArchivoCSR = () => {
        getCSRAfip().then(res => {
            if (res.errores.length > 0) {
                alert(res.errores.join(','))
                return
            }
            let wasDownloaded = true
            var blob = new Blob([res.data], { type: 'text/plain' });
            const a = document.createElement('a');
            a.download = 'afip.csr';
            a.href = URL.createObjectURL(blob);
            a.addEventListener('click', (e) => {
                setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
            });
            a.click();
            onFormChange(wasDownloaded)
        })
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Normal>{Traduccion('CFG_AFIP_PASO_5')}.</Normal>
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained"
                    color="secondary"
                    startIcon={<CloudDownloadIcon />}
                    onClick={descargarArchivoCSR}
                >
                    {Traduccion('TXT_DESCARGAR')}
                </Button>
            </Grid>
        </Grid>
    )
}

const Step8Afip = ({ onFormChange }) => {

    const [alias, setAlias] = React.useState("")
    const [file, setFile] = React.useState(null)
    const [formSubmited, setFormSubmited] = React.useState(false)
    const [crtSaved, setCrtSaved] = React.useState(false)

    const onAliasChange = (val) => {
        setFormSubmited(true)
        setAlias(val)
    }

    const onFileSelect = (e) => {
        setFormSubmited(true)
        let file = e.target.files[0]
        if (!file.name.includes('.crt', file.name.length - 4)) {
            alert(Traduccion('ERR_ARCHIVO_ELEGIDO_NO_VALIDO'))
            setFile(null)
            return
        }
        setFile(file)
    }

    const onAliasAndCertProcess = async () => {
        enableAfip({ alias: alias, certP12: await file.text() })
            .then(d => {
                if (d.errores.length) {
                    alert(d.errores.join('\n'))
                    return
                }
                let wasCrtSaved = true
                setCrtSaved(wasCrtSaved)
                onFormChange(wasCrtSaved)
            })
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Normal>{Traduccion('CFG_AFIP_PASO_8a')}.</Normal>
            </Grid>
            <Grid item xs={12}>
                <InputTexto label={Traduccion('TXT_ALIAS')}
                    value={alias}
                    onChange={onAliasChange}
                    validarSubmit={formSubmited}
                    editable={!crtSaved}
                    requerido />
            </Grid>
            <Grid item xs={12}>
                <Normal>{Traduccion('CFG_AFIP_PASO_8b')}.</Normal>
            </Grid>
            <Grid item xs={12}>
                <InputFile file={file} onFileSelect={onFileSelect} editable={!crtSaved} />
            </Grid>
            <Grid item xs={12} align="center">
                <Button onClick={onAliasAndCertProcess}
                    color="primary"
                    variant="contained"
                    disabled={crtSaved}>
                    {Traduccion('TXT_GUARDAR')}
                </Button>
            </Grid>
        </Grid>
    )
}

const Step14Afip = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Normal>{Traduccion('CFG_AFIP_PASO_14')}.</Normal>
            </Grid>
        </Grid>
    )
}

const SimpleStep = ({ text, imageRoute }) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Normal>{Traduccion(text)}.</Normal>
            </Grid>
            <Grid item xs={12}>
                <CardMedia image={imageRoute} sx={styles.imgSteps} />
            </Grid>
        </Grid>
    )
}