import React from "react"
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { TooltipButton } from "./TooltipButton"
import { PropTypes } from 'prop-types'

export const MoreOptionsMenu = ({options}) => {

    const [elemToAnchor, setElemToAnchor] = React.useState(null)

    const showMenu = (val) => val ? setElemToAnchor(val.currentTarget) : setElemToAnchor(null)

    const execAction = (action) => {
        action()
        showMenu(false)
    }

    return <div>
        <TooltipButton label="TXT_MAS_OPCIONES" onClick={e => showMenu(e)}>
            <MoreVertIcon />
        </TooltipButton>
        <Menu anchorEl={elemToAnchor}
            open={Boolean(elemToAnchor)}
            onClose={() => showMenu(false)}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}>
                {options.map((option, idx) => 
                    <MenuItem key={idx} onClick={() => execAction(option.action)}>
                        <ListItemIcon>
                            {option.icon}
                        </ListItemIcon>
                        <ListItemText>
                            {option.text}
                        </ListItemText>
                    </MenuItem>
        )}
        </Menu>
    </div>
}

MoreOptionsMenu.propTypes = {
    options: PropTypes.arrayOf(
            PropTypes.shape({
                icon: PropTypes.element,
                text: PropTypes.string.isRequired,
                action: PropTypes.func.isRequired
            })
        ).isRequired
};