import { GetCall, PostCall } from './_serviceHelperCor'

const serviceUrl = '/cajas/movimientos';

export const listar = (filters, responseStatus) => {
    var params = [
        {'key': 'cajaId', 'val': filters.caja.id},
        {'key': 'motivoId', 'val': filters.motivoId}
    ];
    return GetCall(serviceUrl, params, responseStatus);
};

export const agregar = (objeto, responseStatus) => {
    let auxDetProd = objeto.detallesProductos.map(elem => {
        return {
            producto: {id: elem.producto.id},
            cantidad: elem.cantidad,
            precio: elem.precio
        };
    });
    let auxDetCob = objeto.detallesCobros.map(elem => {
        return {
            tipoCobro: {id: elem.tipoCobro.id},
            importe: elem.importe
        };
    });
    let objMin = {
        caja: {
            id: objeto.caja.id
        },
        motivoMovimiento: {
            id: objeto.motivoMovimiento.id
        },
        detallesProductos: auxDetProd,
        detallesCobros: auxDetCob,
        consumoMesaId: objeto.consumoMesaId,
        clienteId: objeto.cliente?.id
    };
    return PostCall(serviceUrl, objMin, responseStatus);
};

export const listarDetallesCobros = (filters, responseStatus) => {
    var params = [
        {'key': 'id', 'val': filters.id}
    ];
    return GetCall(serviceUrl + '/DetallesCobros', params, responseStatus);
};

export const listarDetallesProductos = (filters, responseStatus) => {
    var params = [
        {'key': 'id', 'val': filters.id}
    ];
    return GetCall(serviceUrl + '/DetallesProductos', params, responseStatus);
};

export const resumenMovimientos = (filters, responseStatus) => {
    var params = [
        {'key': 'id', 'val': filters.id}
    ];
    return GetCall(serviceUrl + '/resumen', params, responseStatus);
};