import React from 'react'
import { FormControlLabel, Switch } from '@mui/material'

export const Switcher = ({id, label, onChange, value = false, editable = true}) => {
    return (
            <FormControlLabel label={label}
                              disabled={!editable}
                              control={
                                    <Switch id={id?id:Math.random()} 
                                        checked={value} 
                                        onChange={e => onChange(e.target.checked)}
                                    />}
                />)
}