import { GetCall } from './_serviceHelperApi'

const serviceUrl = '/cajas'

export const reporteVentasConcretadas = (filtros) => {
    var params = [
        {'key': 'agrupa', 'val': filtros?.agrupa}
    ]
    return GetCall(serviceUrl+'/reportes/ventasConcretadas', params)
}

export const reporteProductosMasYMenosVendidos = (filtros) => {
    var params = [
        {'key': 'periodo', 'val': filtros?.periodo}
    ]
    return GetCall(serviceUrl+'/reportes/productosMasYMenosVendidos', params)
}

export const reporteIngresosPorUsuario = (filtros) => {
    var params = [
        {'key': 'periodo', 'val': filtros?.periodo}
    ]
    return GetCall(serviceUrl+'/reportes/ingresosPorUsuario', params)
}

export const reporteIngresosPorFormaDePago = (filtros) => {
    var params = [
        {'key': 'periodo', 'val': filtros?.periodo}
    ]
    return GetCall(serviceUrl+'/reportes/ingresosPorFormaDePago', params)
}
